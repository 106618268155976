import { Modal, Popconfirm, Popover, Switch, Table, TableColumnsType } from "antd"
import { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import api from '../../../config/Config'
import { Link, useNavigate } from "react-router-dom";
import requestUserAccount from "../../../controller/requestUserAccount";
import { CheckOutlined, CloseOutlined, EllipsisOutlined } from "@ant-design/icons";

const UserAccount = () => {
    const data = '';
    const navigate = useNavigate();
    const [load, setLoad] = useState<requestUserAccount[]>([])
    const [Loading, setLoading] = useState(false)
    const [totalPage, setTotalPage] = useState(1)


    useEffect(() => {
        if (localStorage.getItem('onktxonktxwwww') == null) {
            navigate('/login')
        }
        LoadUserAccount(1)
    }, []);

    const LoadUserAccount = async (page: any) => {
        setLoading(true)
        api.getHttpRequest(`/user/loads?page=${page}`, data, 'GET').then((response) => {
            const json = response.data;
            setLoad(json.data.result)
            setTotalPage(json.data.count)
            setLoading(false)

        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const dataUser = load.map((item, i) => ({
        ...item,
        key: i,

    }))

    const LoadTableUser: TableColumnsType<requestUserAccount> = [
        {
            title: 'FullName',
            dataIndex: 'name'
        },
        {
            title: 'Gender',
            dataIndex: 'gender'
        },
        {
            title: 'Phone',
            dataIndex: 'phone'
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            render: (createdAt) => api.geDateFormat(createdAt)
        },
        {
            title: 'KYC',
            dataIndex: 'is_kyc',
            render: (is_kyc, record) => {
                if (is_kyc === 0) {
                    return (
                        <>
                            <span style={{ backgroundColor: '#FCF1ED', color: 'black', padding: 5, borderRadius: 5, marginRight: 5 }}>
                                KYC not enabled
                            </span>
                            <Popconfirm title={`Are you sure you want to change the KYC ?`} onConfirm={() => activateKyc(record.id, is_kyc)}>
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked={false}

                                />
                            </Popconfirm>

                        </>
                    );
                } else if (is_kyc === 1) {
                    return (
                        <>
                            <span style={{ backgroundColor: '#F0F6F5', color: 'black', padding: 5, borderRadius: 5, marginRight: 5 }}>KYC is enabled</span>
                            <Popconfirm title={`Are you sure you want to change the KYC ?`} onConfirm={() => activateKyc(record.id, is_kyc)}>
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked={true}

                                />
                            </Popconfirm>

                        </>
                    )
                }
            },
        },
        {
            title: 'Account Verification',
            dataIndex: 'verified',
            render: (verified, record) => {
                if (verified === 0) {
                    return (
                        <>
                            <span style={{ backgroundColor: '#FCF1ED', color: 'black', padding: 5, borderRadius: 5, marginRight: 5 }}>
                                Account not enabled
                            </span>
                            <Popconfirm title={`Are you sure you want to change the Account ?`} onConfirm={() => verifyAccount(record.id, verified)}>
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked={false}

                                />
                            </Popconfirm>

                        </>
                    );
                } else if (verified === 1) {
                    return (
                        <>
                            <span style={{ backgroundColor: '#F0F6F5', color: 'black', padding: 5, borderRadius: 5, marginRight: 5 }}>Account is enabled</span>
                            <Popconfirm title={`Are you sure you want to change the Account ?`} onConfirm={() => verifyAccount(record.id, verified)}>
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked={true}

                                />
                            </Popconfirm>

                        </>
                    )
                }
            },
        },
        {
            title: 'Verify At',
            dataIndex: 'verifiedAt',
            render: (verifiedAt) => api.geDateFormat(verifiedAt)
        },
        {
            title: 'Password',
            dataIndex: 'is_forgot_password',
            render: (is_forgot_password, record) => {
                if (is_forgot_password === 0) {
                    return (
                        <>
                            <span style={{ backgroundColor: '#F0F6F5', color: 'black', padding: 5, borderRadius: 5, marginRight: 5 }}>
                            Correct <br/>
                              
                            </span>
                            {api.geDateFormat(record.forgot_passwordAt)}

                        </>
                    );
                } else if (is_forgot_password === 1) {
                    return (
                        <>
                            <span style={{ backgroundColor: '#FCF1ED', color: 'black', padding: 5, borderRadius: 5, marginRight: 5 }}>Changed</span>
                
                        </>
                    )
                }
            },
        }

    ]

    {/**Active or desactive kyc */ }
    const activateKyc = async (id: any, is_kyc: any) => {
        if (is_kyc === 0) {
            api.getHttpRequest(`/user/set-verify-user-kyc/${id}/1`, {}, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    toast.success(json.message)
                    LoadUserAccount(1)
                }
            }).catch((error) => {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            })
        } else if (is_kyc === 1) {
            api.getHttpRequest(`/user/set-verify-user-kyc/${id}/0`, {}, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    toast.success(json.message)
                    LoadUserAccount(1)
                }
            }).catch((error) => {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            })
        }

    }

    const verifyAccount = async (id: any, verified: any) => {
        if (verified === 0) {
            api.getHttpRequest(`/user/set-verify-user-account/${id}/1`, {}, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    toast.success(json.message)
                    LoadUserAccount(1)
                }
            }).catch((error) => {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            })
        } else {
            api.getHttpRequest(`/user/set-verify-user-account/${id}/0`, {}, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    toast.success(json.message)
                    LoadUserAccount(1)
                }
            }).catch((error) => {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            })
        }
    }


    return (
        <>
            <div className="row m-0  bg-white profilePage">
                <div className="row" >
                    <div>
                        <Toaster
                            position="bottom-left"
                            reverseOrder={false}
                        />
                    </div>
                    <div className="col-10">
                        {/* <h5 className="fs-5 mb-2">Manage use account:</h5> */}
                        <div style={{ display: 'flex', marginLeft: 10, paddingBottom: 10 }}>
                            <label style={{
                                backgroundColor: '#4920E9',
                                height: 20,
                                width: 6,
                                borderRadius: 10
                            }}></label>
                            <strong style={{ paddingLeft: 2 }}>List of user account</strong>
                        </div>
                    </div>
                    {/* <div className="col-2">
                        <div className="d-flex mb-2 justify-content-end" style={{ display: 'flex' }}>
                            <button className="btn w-100 btn-primary " >New roles</button>
                        </div>

                    </div> */}
                </div>

                <div className="row m-0">
                    <div className="col-12 px-0">

                        <Table
                            columns={LoadTableUser}
                            dataSource={dataUser}
                            size="small"
                            loading={Loading}
                            pagination={{
                                pageSize: 10,
                                total: totalPage,
                                onChange: (page: any) => {
                                    LoadUserAccount(page)
                                }
                            }}
                        >

                        </Table>

                    </div>
                </div>

            </div>
        </>
    )
}

export default UserAccount