import Header from "../header"
import PageContent from "../pageContent"

const Container = () => {
    return (
        <>
           <Header/>
            <div className="mainDashboard ">
                <div className="shadowBox"></div>
                <PageContent/>
            </div>
        </>
    )
}

export default Container