import Icon, { CaretDownOutlined, EllipsisOutlined, PlusOutlined, SolutionOutlined } from "@ant-design/icons"
import { Card, Modal, Popconfirm, Popover, Select, Table, TableColumnsType } from "antd"
import { useEffect, useState } from "react"
import toast, { Toaster } from 'react-hot-toast';
import api from '../../config/Config'
import { Button, Drawer, Radio, Switch } from 'antd';
import type { DrawerProps } from 'antd/es/drawer';
import RowParams from "../../controller/requestRowParams";
import { Link, useNavigate } from "react-router-dom";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const { Option } = Select;

const Partners = () => {
    const data = '';
    const navigate = useNavigate();

    const [Loading, setLoading] = useState(false);
    const [load, setLoad] = useState<RowParams[]>([]);
    const [loadRole, setLoadRole] = useState<RowParams[]>([]);
    const [loadRole1, setLoadRole1] = useState<RowParams[]>([]);


    //Element Jv form
    const [name, setName] = useState('');
    const [lastname, setLastName] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [municipalityId, setMunicipalityId] = useState('');
    const [avatar, setAvatar] = useState('');
    const [gender, setGender] = useState('');
    const [UserRoleId, setUserRoleId] = useState('');
    const [civilStatus, setCivilStatus] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [placeOfBirth, setPlaceOfBirth] = useState('');
    const [isSellerAccount, setIsSellerAccount] = useState('');
    const [idjv, setIdJv] = useState('')
    const [investorId, setInvestorId] = useState('')

    {/**Variable to attribute investment */ }
    const [idUser, setIdUser] = useState('');
    const [observation, setObservation] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [amount, setAmount] = useState('')
    const [currency, setCurrency] = useState('')
    const [balance, setBalance] = useState('')
    const [InvestmentCategoryId, setInvestmentCategoryId] = useState('')
    const [targetMembers, setTargetMembers] = useState('')
    const [percentageForSellers, setPercentageForSellers] = useState('')
    const [percentage, setPercentage] = useState('')
    


    {/** Select name selllers */ }
    const [getName, setGetName] = useState('')

    // Loard all
    const [loadProvince, setLoadPronvince] = useState<RowParams[]>([])
    const [loadCity, setLoadCity] = useState<RowParams[]>([])
    const [loadminicipality, setLoadMinicipality] = useState<RowParams[]>([])
    const [loadConstant, setLoadConstant] = useState<RowParams[]>([])
    const [LoadInvestment, setLoadInvestment] = useState<RowParams[]>([])
    const [selectRole, setSelectRole] = useState("");
    const [selectInvestment, setSelectInvestment] = useState("");
    const [ShowNameInvestment, setShowNameInvestment] = useState("");


    // useEffect(() => {


    //     setSelectRole(LoadInvestment.length > 0 ? LoadInvestment[0].id : "");
    // }, [LoadInvestment]);

    useEffect(() => {
        if (localStorage.getItem('onktxonktxwwww') == null) {
            navigate('/login')
        }

        // LoadPartners(); 
        LoadRole()
        LoadRoleInvestment()
        LoadProvince()
        LoadCity()
        LoadMinicipality()
        LoadInventoryCategory()
        LoadConstant()
    }, []);


    const partnerId1 = loadRole1.find((item: any) => item.role === 'JV Partner')?.id;
    // useEffect(() => {

    //     const LoadPartners = async () => {

    //         setLoading(true);
    //         api.getHttpRequest(`/investments/load/${partnerId}`, data, 'GET').then((response) => {
    //             const json = response.data;
    //             setLoad(json.data)
    //             setLoading(false);
    //         }).catch((error) => {
    //             if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
    //                 toast.error("Please connect your device to the internet");
    //             } else if (error.response && error.response.data && error.response.data.message) {
    //                 toast.error(error.response.data.message);
    //             } else {
    //                 toast.error(error.message);
    //             }
    //         })
    //     }
    //     LoadPartners();
    // }, [partnerId])

    // useEffect(() => {
    //     const LoadPartners = async () => {
    //         setLoading(true);
    //         api.getHttpRequest(`/investments/load-by-categorie/${selectRole}/${selectInvestment}`, data, 'GET').then((response) => {
    //             const json = response.data;
    //             setLoad(json.data)
    //             setLoading(false);
    //         }).catch((error) => {
    //             if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
    //                 toast.error("Please connect your device to the internet");
    //             } else if (error.response && error.response.data && error.response.data.message) {
    //                 toast.error(error.response.data.message);
    //             } else {
    //                 toast.error(error.message);
    //             }
    //         })
    //     }
    //     LoadPartners();
    // }, [selectRole, selectInvestment])


    useEffect(() => {
        const LoadPartners = async () => {
            setLoading(true);
            try {
                const response = await api.getHttpRequest(`/investments/load/${partnerId1}`, data, 'GET');
                const json = response.data;
                setLoad(json.data)
            } catch (error: any) {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Veuillez connecter votre appareil à Internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            }
            setLoading(false);
        };
        LoadPartners();
    }, [partnerId1]);

    useEffect(() => {

        LoadPartners();
    }, [selectRole, selectInvestment]);


    const LoadPartners = async () => {
        setLoading(true);
        if (selectRole && selectInvestment) {
            api.getHttpRequest(`/investments/load-by-categorie/${selectRole}/${selectInvestment}`, data, 'GET').then((response) => {
                const json = response.data;
                setLoad(json.data)
                setLoading(false);
            }).catch((error) => {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            })
        }

    }



    // const LoadPartners = async () => {

    //     setLoading(true);

    //     let url = `/investments/load/${partnerId}`;

    //     if (selectRole && selectInvestment) {
    //         url = `/investments/load-by-categorie/${selectRole}/${selectInvestment}`;
    //     }

    //     api
    //         .getHttpRequest(url, data, "GET")
    //         .then((response) => {
    //             const json = response.data;
    //             setLoad(json.data);
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             if (
    //                 !window.navigator.onLine &&
    //                 !error.response &&
    //                 error.code === "ERR_NETWORK"
    //             ) {
    //                 toast.error("Please connect your device to the internet");
    //             } else if (
    //                 error.response &&
    //                 error.response.data &&
    //                 error.response.data.message
    //             ) {
    //                 toast.error(error.response.data.message);
    //             } else {
    //                 toast.error(error.message);
    //             }
    //         });
    // };



    const LoadRoleInvestment = async () => {
        api.getHttpRequest("/investments/role-investment", data, 'GET').then((response) => {
            const json = response.data;
            setLoadRole1(json.data)

        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const LoadRole = async () => {
        api.getHttpRequest("/parameters/roles/load", data, 'GET').then((response) => {
            const json = response.data;
            setLoadRole(json.data)

        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }


    const LoadInventoryCategory = async () => {
        api.getHttpRequest("/parameters/investment-category/load", data, 'GET').then((response) => {
            const json = response.data;
            setLoadInvestment(json.data)

        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const LoadConstant = async () => {
        api.getHttpRequest("/parameters/constante/load", data, 'GET').then((response) => {
            const json = response.data;
            setLoadConstant(json.data)

        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const LoadProvince = async () => {
        api.getHttpRequest("/zone/province/load", data, 'GET').then((response) => {
            const json = response.data;
            setLoadPronvince(json.data)

        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const LoadCity = async () => {
        api.getHttpRequest("/zone/city/load", data, 'GET').then((response) => {
            const json = response.data;
            setLoadCity(json.data)

        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }
    const LoadMinicipality = async () => {
        api.getHttpRequest("/zone/municipality/load", data, 'GET').then((response) => {
            const json = response.data;
            setLoadMinicipality(json.data)

        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const selectPartners = (id: any, name: any) => {
        setSelectRole(id)
        setShowNameInvestment(name)
    }

    const partner = loadRole.find((item: any) => item.name === 'JV Partner');
    const constante = loadConstant.find((item: any) => item.name === 'Tsh')?.value;

    {/**Bottom to add jv partners */ }
    const addJVPartners = async () => {
        let data = JSON.stringify({
            "name": name,
            "lastname": lastname,
            "middlename": middlename,
            "email": email,
            "address": address,
            "phone": phone,
            "MunicipalityId": municipalityId,
            "avatar": "",
            "gender": gender,
            "UserRoleId": partner?.id,
            "civil_status": civilStatus,
            "date_of_birth": dateOfBirth,
            "place_of_birth": placeOfBirth,
            "is_seller_account": false
        });

        setLoading(true);

        if (name === '' || lastname === '' || municipalityId === '' || address === '' ||
            phone === '' || gender === ''
        ) {
            toast.error('No fields should be empty');

        } else {
            api.getHttpRequest("/member/create", data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    setIdUser(json.data.id)
                    setGetName(name)
                    toast.success(json.message);
                    setOpen2(true);
                    setOpen(false);
                    // LoadPartners()

                } else {
                    toast.error(json.message);
                }
            }).catch((error) => {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            })

        }

        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }

    {/** Bottom to attribute investement to jv */ }

    const AttributeInvestmentToJV = async () => {
        let data = JSON.stringify({
            "observation": observation,
            "start_date": startDate,
            "end_date": endDate,
            "amount": 0,
            "currency": constante,
            "balance": "",
            "InvestmentCategoryId": InvestmentCategoryId,
            "id_user": idjv,
            "target_members": targetMembers,
            "percentage_for_sellers": percentageForSellers,
            "percentage": percentage
        });

        setLoading(true);

        if (observation === '' || startDate === '' || endDate === '' ||
            InvestmentCategoryId === '' || targetMembers === '' || percentageForSellers === '' || percentage === ''
        ) {
            toast.error('No fields should be empty');

        } else {
            api.getHttpRequest("/investments/create", data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 201) {
                    setOpen(false);
                    toast.success(json.message);
                    setOpen2(false);
                    setOpen(false);
                    // LoadPartners()
                } else {
                    toast.error(json.message);
                }
            }).catch((error) => {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            })

        }

        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }

    const deleteInvestment = (value: any) => {
        api.getHttpRequest(`/investments/delete/${value}`, data, 'delete').then((response) => {
            if (response.data.status == 201) {
                toast.success(response.data.message);
                setLoading(false);
                LoadProvince()
            }
            else {
                setLoading(false);
                toast.error(response.data.message);
            }
        }).catch((error) => {
            setLoading(false);
            toast.error(error.message);
        })

    }

    //Open Drawer
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [placement, setPlacement] = useState<DrawerProps['placement']>('bottom');

    const showAdddpartener = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    const onClose2 = () => {
        setOpen2(false);
    };

    {/**show component update  Investment */ }
    const showUpdateInvestment = (element: any) => {
        setOpen3(true);
        const startDate = new Date(element.start_date);
        const formattedStartDate = startDate.toLocaleDateString("fr-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });

        const expireIn = `${element.expire_in}`;
        const endDate = new Date();
        endDate.setFullYear(endDate.getFullYear() + parseInt(expireIn));
        const formattedEndDate = endDate.toLocaleDateString("fr-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
        setGetName(element.investor_name);
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        setAmount(element.amount);
        setCurrency(element.currency);
        setBalance(element.balance);
        setInvestmentCategoryId(element.InvestmentCategoryId);
        setPercentageForSellers(element.percentage_for_sellers);
        setPercentage(element.percentage);
        setObservation(element.observation);
        setIdJv(element.id)
        setInvestorId(element.investor_id)
        setTargetMembers(element.target_member)
    }
    const onClose3 = () => {
        setOpen3(false);
    };

    {/**Fonction Button update Partner Investment */ }
    const buttonUpdatePartnerInvestment = (id: any) => {
        let data = JSON.stringify({
            "observation": observation,
            "start_date": startDate,
            "end_date": endDate,
            // "amount": amount,
            // "currency": currency,
            // "balance": balance,
            "InvestmentCategoryId": InvestmentCategoryId,
            "id_user": investorId,
            "target_members": targetMembers,
            "percentage": percentage,
            "percentage_for_sellers": percentageForSellers
        });
        setLoading(true)
        if (startDate === '' || endDate === '' || targetMembers === '' || observation === '' ||
             InvestmentCategoryId === '' || investorId === '' || percentage === '' || percentageForSellers === ''
        ) {
            toast.error('No fields should be empty');
        } else {
            api.getHttpRequest(`/investments/update/${id}`, data, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    setOpen(false);
                    toast.success(json.message);
                    setOpen2(false);
                    setOpen(false);
                    setLoading(false)
                    setOpen3(false);
                    // LoadPartners()
                } else {
                    setLoading(false)
                    toast.error(json.message);
                }
            }).catch((error) => {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            })
        }

        
    }

    // Fonction qui va permettre des charger le city appartir de la province
    const [stateCity, setStateCity] = useState<RowParams[]>([])
    const [stateMinicipality, setStateMinicipality] = useState<RowParams[]>([])
    const GetProvince = (id: any) => {
        const IdProv = loadCity.filter(x => x.PronvinceId === id);
        setStateCity(IdProv)
    }

    const GetCity = (id: any) => {
        const IdCity = loadminicipality.filter(x => x.CityId === id);
        setStateMinicipality(IdCity)
    }

    {/*** Table ant load Partners */ }
    const dataPartners = load.map((item, i) => ({
        ...item,
        key: i,

    }))

    const LoadTablePartner: TableColumnsType<RowParams> = [
        {
            title: "Name",
            dataIndex: "investor_name",
        },
        {
            title: "Mobile",
            dataIndex: "investor_phone",
            key: "investor_phone",
            render: (text, record) => (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>{record.investor_phone}</span>
                    <span>{record.investor_email}</span>
                </div>
            ),
        },

        {
            title: "Percentage",
            dataIndex: "percentage",
            key: "percentage",
            render: (text) => (
                <span style={{ backgroundColor: 'blue', color:'white', padding:3, borderRadius:5 }}>
                  {`${text}%`}
                </span>
              )
        },
        {
            title: "Seller|%",
            dataIndex: "percentage_for_sellers",
            key: 'percentage_for_sellers',
            render: (text) => (
                <span style={{ backgroundColor: 'cyan', padding:5, borderRadius:5 }}>
                  {`${text}%`}
                </span>
              )
        },
        {
            title: "CreatedAt",
            dataIndex: "start_date",
            render: (start_date) => api.geDateFormat(start_date)
        },
        {
            title: "ExpireIn",
            dataIndex: "expire_in",
        },
        {
            title: "Action",
            render: (_, record) => {
                if (record.investor_role === "Vendor") {
                    return (
                        load.length >= 1 ? (
                            <div >
                                <div>
                                    <span className=" d-flex position-relative">
                                        <Popover
                                            placement="right"
                                            trigger="click"
                                            content={

                                                <div >

                                                    <p style={{ marginBottom: 0 }}><Link to={`/attribute-voucher/${record.id}`} className="dropdown-item" style={{ fontSize: 16 }}>Attribute Voucher</Link></p>

                                                    <div style={{ marginBottom: 0 }}><div onClick={() => handleModal(record.id)} className="dropdown-item" style={{ fontSize: 16, cursor: 'pointer' }}>Edit seller information</div></div>
                                                    {/* <p style={{marginBottom:0}}><Link to="" className="dropdown-item" style={{fontSize:16}} >Edit JV Partner</Link></p> */}
                                                    <Popconfirm title={`Are you sure to delete Investment : ${record.investor_name} ?`} onConfirm={() => deleteInvestment(record.id)}>
                                                        <p style={{ marginBottom: 1, cursor: 'pointer' }}><p className="dropdown-item" style={{ fontSize: 16 }}>Delete</p></p>
                                                    </Popconfirm>

                                                </div>
                                            }
                                        >
                                            <EllipsisOutlined style={{ fontSize: 24 }} />
                                        </Popover>
                                    </span>

                                </div>
                            </div>

                        ) : null
                    );
                } else if (record.investor_role === "JV Partner") {
                    return (
                        load.length >= 1 ? (
                            <div >
                                <div>
                                    <span className=" d-flex position-relative">
                                        <Popover
                                            placement="right"
                                            trigger="click"
                                            content={

                                                <div >

                                                    <p style={{ marginBottom: 0 }}><Link to={`/attribute-voucher/${record.id}`} className="dropdown-item" style={{ fontSize: 16 }}>Attribute Voucher</Link></p>

                                                    <div style={{ marginBottom: 0 }}><div onClick={() => showUpdateInvestment(record)} className="dropdown-item" style={{ fontSize: 16, cursor: 'pointer' }}>Edit Partner Investment</div></div>
                                                    {/* <p style={{marginBottom:0}}><Link to="" className="dropdown-item" style={{fontSize:16}} >Edit JV Partner</Link></p> */}
                                                    <Popconfirm title={`Are you sure to delete Investment : ${record.investor_name} ?`} onConfirm={() => deleteInvestment(record.id)}>
                                                        <p style={{ marginBottom: 1, cursor: 'pointer' }}><p className="dropdown-item" style={{ fontSize: 16 }}>Delete</p></p>
                                                    </Popconfirm>

                                                </div>
                                            }
                                        >
                                            <EllipsisOutlined style={{ fontSize: 24 }} />
                                        </Popover>
                                    </span>

                                </div>
                            </div>

                        ) : null
                    );
                }
                else {
                    return (
                        load.length >= 1 ? (
                            <div >
                                <div>
                                    <span className=" d-flex position-relative">
                                        <Popover
                                            placement="right"
                                            trigger="click"
                                            content={

                                                <div >

                                                    <p style={{ marginBottom: 0 }}><Link to={`/attribute-voucher/${record.id}`} className="dropdown-item" style={{ fontSize: 16 }}>Attribute Voucher</Link></p>
                                                    {/* <p style={{marginBottom:0}}><Link to="" className="dropdown-item" style={{fontSize:16}} >Edit JV Partner</Link></p> */}
                                                    <Popconfirm title={`Are you sure to delete Investment : ${record.investor_name} ?`} onConfirm={() => deleteInvestment(record.id)}>
                                                        <p style={{ marginBottom: 1, cursor: 'pointer' }}><p className="dropdown-item" style={{ fontSize: 16 }}>Delete</p></p>
                                                    </Popconfirm>

                                                </div>
                                            }
                                        >
                                            <EllipsisOutlined style={{ fontSize: 24 }} />
                                        </Popover>
                                    </span>

                                </div>
                            </div>

                        ) : null
                    );
                }
            }
        }

    ]

    //Open modal edit sellers information
    const [openModal, setOpenModal] = useState(false)
    const [confirmLoading2, setConfirmLoading2] = useState(false);
    const [id, setId] = useState('');

    const handleModal = (id: any) => {
        setConfirmLoading2(true);
        setTimeout(() => {
            setId(id);
            setOpenModal(true);
            setConfirmLoading2(false);
        }, 2000);
    };

    const handleCancelModal = () => {
        setOpenModal(false);
    };

    const [placeOfOperation, setPlaceOfOperation] = useState('');
    const [operationLocationType, setOperationLocationType] = useState('');
    const [countLimitVouchers, setCountLimitVouchers] = useState('');
    const [isLicence, setIsLicence] = useState(0);

    const UpdateSellerInformation = (id: any) => {

        let data = JSON.stringify({
            "place_of_operation": placeOfOperation,
            "operation_location_type": operationLocationType,
            "count_limit_vouchers": countLimitVouchers,
            "is_licence": isLicence
        });

        setLoading(true);

        if (placeOfOperation === '' || operationLocationType === '' || countLimitVouchers === '') {
            toast.error('No fields should be empty');
        }
        else {
            api.getHttpRequest(`/investments/update-investment-seller-infos/${id}`, data, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    setOpen(false);
                    toast.success(json.message);
                    setOpenModal(false);
                    setOpen(false);
                    // LoadPartners()
                } else {
                    setLoading(false);

                    toast.error(json.message);
                }
            }).catch((error) => {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            })
        }
    }

    function handleSwitchChange(checked: any) {
        if (checked) {
            setIsLicence(1);
        } else {
            setIsLicence(0);
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row" >
                    <div>
                        <Toaster
                            position="bottom-left"
                            reverseOrder={false}
                        />
                    </div>
                    <div className="col-5 mb-2">
                        <div className="pagesHeading d-flex">
                            {/* <div className="pageIcon item-center bgProfile">
                     
                                <i className="fas fa-person-booth fa-lg  align-middle me-3" style={{ color: 'white', width: 40, height: 40, borderRadius: 5 }}></i>
                            </div> */}

                            <div className="my-auto ms-1 d-flex">
                                <div>
                                    <span className="small-font">Partners</span>
                                    <span className="tableHeading d-flex position-relative">
                                        <SolutionOutlined style={{ fontSize: 24 }} /> Our partners
                                        <div className="dropdown categorydrop">
                                            <button className="btn p-0" type="button" id="categoryMenu" data-bs-toggle="dropdown" aria-expanded="false">
                                                {/* <i className="fa-solid fa-caret-down ms-3 fs-5"></i> */}
                                                <CaretDownOutlined className="ms-3 fs-5" style={{ color: 'black' }} />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="categoryMenu">
                                                <h5>Sellers Categories</h5>
                                                {
                                                    loadRole1.map((item, i) => (
                                                        <li style={{ cursor: 'pointer', fontSize: 18 }} key={i}>
                                                            <div className="dropdown-item" onClick={() => selectPartners(item.id, item.role)}>{item.role} ({item.nombre})</div>
                                                        </li>
                                                    ))
                                                }


                                            </ul>
                                        </div>
                                    </span>
                                </div>
                                <div className="mt-3 ">

                                    <div className="ms-1 mb-1 mt-1 d-flex">
                                        <p style={{ display: 'none' }}>{selectInvestment}</p>
                                    </div>

                                    <div className="ms-1 justify-content-end" style={{ display: 'flex' }}>
                                        <span className="btn w-100 " style={{ backgroundColor: '#F0F6F5', fontSize: 18 }} >
                                            Select: {ShowNameInvestment}</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-7">
                        <div className="d-flex mt-3 justify-content-end" style={{ display: 'flex' }}>

                            <div className="ms-1 mb-1  mt-1">

                                <select className="form-select  w-100" id="gender"

                                    name='selectInvestment'
                                    value={selectInvestment}
                                    onChange={(e) => { setSelectInvestment(e.target.value) }}
                                >

                                    {LoadInvestment.map((item, i) => (
                                        <option key={i} value={item.id}>{item.name}</option>
                                    ))}

                                </select>


                            </div>

                            <div className="ms-1 mb-1">
                                <button className="btn w-100 " style={{ backgroundColor: '#16C0EC', fontSize: 20 }} onClick={showAdddpartener}>
                                    <PlusOutlined style={{ fontSize: 24 }}
                                    /> Add JV Partners</button>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row m-0">
                    <div className="col-12 px-0">
                        <Card style={{ opacity: 0.8 }}>
                            {/* <table className="table secondTable resizable">
                                <thead>
                                    <tr>
                                        <th style={{ width: 10, paddingLeft: 15 }}>#</th>
                                        <th >Name</th>

                                        <th >Mobile</th>
                                        <th >Percentage</th>
                                        <th>Category</th>
                                        <th >createdAt</th>
                                        <th >updatedAt</th>
                                        <th >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        load.map((item, i) => (

                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{item.investor_name}</td>

                                                <td>
                                                    <div style={{ display: 'row' }}>
                                                        <span>{item.investor_phone}</span><br />
                                                        <span>{item.investor_email}</span>
                                                    </div>
                                                </td>
                                                <td>{item.percentage}</td>
                                                <td>{item.categorie}</td>
                                                <td>{api.geDateFormat(item.start_date)}</td>
                                                <td>{item.expire_in}</td>
                                                <td style={{ width: 15 }}>
                                                    <div >
                                                        <div>
                                                            <span className=" d-flex position-relative">
                                                                <Popover
                                                                    placement="right"
                                                                    trigger="click"
                                                                    content={
                                                                        <div >

                                                                            <p style={{ marginBottom: 0 }}><Link to={`/attribute-voucher/${item.id}`} className="dropdown-item" style={{ fontSize: 16 }}>Attribute Voucher</Link></p>

                                                                            <Popconfirm title={`Are you sure to delete Investment : ${item.investor_name} ?`} onConfirm={() => deleteInvestment(item.id)}>
                                                                                <p style={{ marginBottom: 1, cursor: 'pointer' }}><p className="dropdown-item" style={{ fontSize: 16 }}>Delete</p></p>
                                                                            </Popconfirm>

                                                                        </div>
                                                                    }
                                                                >
                                                                    <EllipsisOutlined style={{ fontSize: 24 }} />
                                                                </Popover>
                                                            </span>

                                                        </div>
                                                    </div>

                                                </td>
                                            </tr>

                                        ))
                                    }

                                </tbody>
                            </table> */}

                            <Table
                                columns={LoadTablePartner}
                                dataSource={dataPartners}
                                size="small"
                                loading={Loading}
                            >

                            </Table>

                        </Card>
                    </div>
                </div>
            </div>



            <Drawer
                title="Add Parteners"
                placement={'bottom'}
                width={500}
                height={450}
                onClose={onClose}
                open={open}

            >
                <div className="row  ">
                    <div className="col-3">
                        <div className="inputBox mb-3">
                            <input type="text" className="w-100 mt-0" placeholder="Name"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="inputBox mb-3">
                            <input className="w-100" placeholder="Middle Name" style={{ color: 'black' }}
                                name="middlename"
                                value={middlename}
                                onChange={(e) => setMiddlename(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col-3">
                        <div className="inputBox mb-3">
                            <input type="text" className="w-100" placeholder="Last Name" style={{ color: 'black' }}
                                name="lastname"
                                value={lastname}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="inputBox mb-3">
                            <input type="date" className="w-100" placeholder="Date of Birth" style={{ color: 'black' }}
                                name="dateOfBirth"
                                value={dateOfBirth}
                                onChange={(e) => setDateOfBirth(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="inputBox mb-0">
                            <select className="form-select" id="gender"
                                name='gender'
                                value={gender}
                                onChange={(e) => { setGender(e.target.value) }}
                            >
                                <option>Select/Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>

                        </div>
                    </div>
                    <div className="col-3">
                        <div className="inputBox mb-3">
                            <input type="text" className="w-100" placeholder="Place of Birth" style={{ color: 'black' }}
                                name="placeOfBirth"
                                value={placeOfBirth}
                                onChange={(e) => setPlaceOfBirth(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="inputBox mb-3">
                            <input type="text" className="w-100" placeholder="Phone" style={{ color: 'black' }}
                                name="phone"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col-3">
                        <div className="inputBox mb-3">
                            <input type="text" className="w-100" placeholder="Email" style={{ color: 'black' }}
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col-3">
                        <div className="inputBox mb-0">
                            <select className="form-select"
                                name="civilStatus"
                                value={civilStatus}
                                onChange={(e) => setCivilStatus(e.target.value)}
                            >
                                <option>Select/Civil Status</option>
                                <option value='Married'>Married</option>
                                <option value='Celibator'>Celibator</option>
                                <option value='Widow'>Widow</option>
                                <option value='Widower'>Widower</option>
                                <option value='Divorce'>Divorce</option>
                            </select>

                        </div>
                    </div>

                    <div className="col-3">
                        <div className=" mb-3">
                            <Select showSearch style={{ width: "100%" }} 

                                placeholder="Select province" optionFilterProp="children" onChange={GetProvince}
                                filterOption={(input: string, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                <Option selected value="">Select Province
                                </Option>
                                {
                                    loadProvince &&
                                        loadProvince !== undefined ?
                                        loadProvince.map((item, i) => (
                                            <Option key={i} value={item.id}>{item.name}</Option>
                                        ))
                                        : "No Province"
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className=" mb-3">

                            <Select showSearch style={{ width: "100%", color: 'black' }} dropdownStyle={{ height: 350 }}

                                placeholder="Select Distict" optionFilterProp="children" onChange={(e) => GetCity(e)}
                                filterOption={(input: string, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option selected value="">Select State/Disctrict
                                </Option>
                                {
                                    stateCity &&
                                        stateCity !== undefined || stateCity !== null ?
                                        stateCity.map((item, i) => (
                                            <Option key={i} value={item.id}>{item.name}</Option>
                                        ))
                                        : "No City"
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className=" mb-3">

                            <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 350 }}

                                placeholder="Select Ward/Kata" optionFilterProp="children" value={municipalityId} onChange={(e) => { setMunicipalityId(e) }}
                                filterOption={(input: string, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                <Option selected value="">Select Ward/Kata
                                </Option>
                                {
                                    stateMinicipality &&
                                        stateMinicipality !== undefined || stateMinicipality !== null ?
                                        stateMinicipality.map((item, i) => (
                                            <Option key={i} value={item.id}>{item.name}</Option>
                                        ))
                                        : "No minicipality"
                                }
                            </Select>

                        </div>
                    </div>
                    <div className="col-12">
                        <div className="inputBox mb-3">
                            <textarea className="w-100" rows={1} placeholder="Adress"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </div>
                    </div>
                    {/* <div className="col-3">
                        <div className="inputBox mb-0">
                            <select className="form-select"  id="gender"
                                name='isSellerAccount'
                                value={isSellerAccount}
                                onChange={(e) => { setIsSellerAccount(e.target.value) }}
                            >
                                <option>Is seller account</option>
                                {
                                    LoadInvestment.map((item, i) => (
                                        <option key={i} value={item.id}>{item.name}</option>
                                    ))
                                }
                                
                                
                            </select>

                        </div>
                    </div> */}
                    <div className="col-8"></div>
                    <div className="col-4">
                        <div className="inputBox mb-3">
                            {!Loading && (
                                <button onClick={addJVPartners} type="button" className="btn w-100  rounded-pill" style={{ backgroundColor: '#16C0EC' }}>Save JV parter</button>
                            )}

                            {Loading && (
                                <button disabled type="button" className="btn w-100 rounded-pill" style={{ backgroundColor: '#16C0EC' }}>
                                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                </button>
                            )}

                        </div>
                    </div>
                </div>
            </Drawer>

            {/***Add Amount */}
            <Drawer
                title={`Attribute Investment to :` + ' ' + getName}
                placement={'bottom'}
                width={500}
                height={450}
                onClose={onClose2}
                open={open2}

            >
                <div className="row  ">
                    {/* <div className="col-3">
                        <div className="inputBox mb-3">
                            <input type="number" className="w-100 mt-0" placeholder="Amount"
                                name="amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                    </div> */}
                    <div className="col-4">
                        <div className="inputBox mb-3">
                            <input type="date" className="w-100" placeholder="Start Date" style={{ color: 'black' }}
                                name="startDate"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="inputBox mb-3">
                            <input type="date" className="w-100" placeholder="End Date" style={{ color: 'black' }}
                                name="endDate"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className=" mb-0">

                            <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}

                                placeholder="Select minicipality" optionFilterProp="children" value={InvestmentCategoryId} onChange={(e) => { setInvestmentCategoryId(e) }}
                                filterOption={(input: string, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                <Option selected value="">Select/Investment category
                                </Option>
                                {
                                    LoadInvestment &&
                                        LoadInvestment !== undefined || LoadInvestment !== null ?
                                        LoadInvestment.map((item, i) => (
                                            <Option key={i} value={item.id}>{item.name}</Option>
                                        ))
                                        : "No Investment category"
                                }
                            </Select>

                        </div>
                    </div>
                    <div className="col-4">
                        <div className="inputBox mb-3">
                            <input type="number" className="w-100" placeholder="Target members" style={{ color: 'black' }}
                                name="placeOfBirth"
                                value={targetMembers}
                                onChange={(e) => setTargetMembers(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="inputBox mb-3">
                            <input type="number" className="w-100" placeholder="Percentage" style={{ color: 'black' }}
                                name="percentage"
                                value={percentage}
                                onChange={(e) => setPercentage(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="inputBox mb-3">
                            <input type="number" className="w-100" placeholder="Percentage for sellers" style={{ color: 'black' }}
                                name="percentageForSellers"
                                value={percentageForSellers}
                                onChange={(e) => setPercentageForSellers(e.target.value)}
                            />
                        </div>
                    </div>

                    {/* <div className="col-3">
                        <div className=" mb-0">

                            <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}

                                placeholder="Select minicipality" optionFilterProp="children" value={currency} onChange={(e) => { setCurrency(e) }}
                                filterOption={(input: string, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                <Option selected value="">Select/Currency
                                </Option>
                                {
                                    loadConstant &&
                                        loadConstant !== undefined || loadConstant !== null ?
                                        loadConstant.map((item, i) => (
                                            <Option key={i} value={item.id}>{item.name}</Option>
                                        ))
                                        : "No Investment category"
                                }
                            </Select>

                        </div>
                    </div> */}


                    <div className="col-12">
                        <div className="inputBox mb-3">
                            <textarea className="w-100" rows={1} placeholder="Observation"
                                value={observation}
                                onChange={(e) => setObservation(e.target.value)}
                            />
                        </div>
                    </div>
                    {/* <div className="col-3">
                        <div className="inputBox mb-0">
                            <select className="form-select"  id="gender"
                                name='isSellerAccount'
                                value={isSellerAccount}
                                onChange={(e) => { setIsSellerAccount(e.target.value) }}
                            >
                                <option>Is seller account</option>
                                {
                                    LoadInvestment.map((item, i) => (
                                        <option key={i} value={item.id}>{item.name}</option>
                                    ))
                                }
                                
                                
                            </select>

                        </div>
                    </div> */}
                    <div className="col-8"></div>
                    <div className="col-4">
                        <div className="inputBox mb-3">
                            {!Loading && (
                                <button onClick={AttributeInvestmentToJV} type="button" className="btn w-100  rounded-pill" style={{ backgroundColor: '#16C0EC' }}>Save Investment</button>
                            )}

                            {Loading && (
                                <button disabled type="button" className="btn w-100 rounded-pill" style={{ backgroundColor: '#16C0EC' }}>
                                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                </button>
                            )}

                        </div>
                    </div>
                </div>
            </Drawer>

            {/**Update JV partner Amount */}

            <Drawer
                title={`Modify` + ' ' + getName + "'S " + `Investment`}
                placement={'bottom'}
                width={500}
                height={450}
                onClose={onClose3}
                open={open3}

            >
                <div className="row  ">
                    {/* <div className="col-3">
                        <div className="inputBox mb-3">
                            <input type="number" className="w-100 mt-0" placeholder="Amount"
                                name="amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                    </div> */}
                    <input style={{display :'none'}}  value={idjv} onChange={(e) => setIdJv(e.target.value)} type="text" />
                    <div className="col-4">
                        <div className="inputBox mb-3">
                            <label htmlFor="">Start Date</label>
                            <input type="date" className="w-100" placeholder="Start Date" style={{ color: 'black' }}
                                name="startDate"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="inputBox mb-3">
                            <label htmlFor="">End Date</label>
                            <input type="date" className="w-100" placeholder="End Date" style={{ color: 'black' }}
                                name="endDate"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className=" mb-0">
                            <label htmlFor="" className="mb-3">Investment category</label>
                            <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 250 }}

                                placeholder="Select minicipality" optionFilterProp="children" value={InvestmentCategoryId} onChange={(e) => { setInvestmentCategoryId(e) }}
                                filterOption={(input: string, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                <Option selected value="">Select/Investment category
                                </Option>
                                {
                                    LoadInvestment &&
                                        LoadInvestment !== undefined || LoadInvestment !== null ?
                                        LoadInvestment.map((item, i) => (
                                            <Option key={i} value={item.id}>{item.name}</Option>
                                        ))
                                        : "No Investment category"
                                }
                            </Select>

                        </div>
                    </div>
                    {/* <div className="col-4">
                        <div className="inputBox mb-3">
                            <label htmlFor="">Amount</label>
                            <input type="number" className="w-100" placeholder="Amount" style={{ color: 'black' }}
                                name="placeOfBirth"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="inputBox mb-3">
                            <label htmlFor="">Balance</label>
                            <input type="number" className="w-100" placeholder="Balance" style={{ color: 'black' }}
                                name="placeOfBirth"
                                value={balance}
                                onChange={(e) => setBalance(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className=" mb-0">
                            <label htmlFor="" className="mb-2">Currency</label>
                            <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}

                                placeholder="Select Currency" optionFilterProp="children" value={currency} onChange={(e) => { setCurrency(e) }}
                                filterOption={(input: string, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                <Option selected value="">Select/Currency
                                </Option>
                                {
                                    loadConstant &&
                                        loadConstant !== undefined || loadConstant !== null ?
                                        loadConstant.map((item, i) => (
                                            <Option key={i} value={item.id}>{item.name}</Option>
                                        ))
                                        : "No Investment category"
                                }
                            </Select>

                        </div>
                    </div> */}
                    <div className="col-4">
                        <div className="inputBox mb-3">
                            <label htmlFor="">Target members</label>
                            <input type="number" className="w-100" placeholder="Target members" style={{ color: 'black' }}
                                name="placeOfBirth"
                                value={targetMembers}
                                onChange={(e) => setTargetMembers(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="inputBox mb-3">
                            <label htmlFor="">Percentage</label>
                            <input type="number" className="w-100" placeholder="Percentage" style={{ color: 'black' }}
                                name="percentage"
                                value={percentage}
                                onChange={(e) => setPercentage(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="inputBox mb-3">
                            <label htmlFor="">Percentage for sellers</label>
                            <input type="number" className="w-100" placeholder="Percentage for sellers" style={{ color: 'black' }}
                                name="percentageForSellers"
                                value={percentageForSellers}
                                onChange={(e) => setPercentageForSellers(e.target.value)}
                            />
                        </div>
                    </div>

                    {/* <div className="col-3">
                        <div className=" mb-0">

                            <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}

                                placeholder="Select minicipality" optionFilterProp="children" value={currency} onChange={(e) => { setCurrency(e) }}
                                filterOption={(input: string, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                <Option selected value="">Select/Currency
                                </Option>
                                {
                                    loadConstant &&
                                        loadConstant !== undefined || loadConstant !== null ?
                                        loadConstant.map((item, i) => (
                                            <Option key={i} value={item.id}>{item.name}</Option>
                                        ))
                                        : "No Investment category"
                                }
                            </Select>

                        </div>
                    </div> */}


                    <div className="col-12">
                        <div className="inputBox mb-3">
                            <label htmlFor="">Observation</label>
                            <textarea className="w-100" rows={1} placeholder="Observation"
                                value={observation}
                                onChange={(e) => setObservation(e.target.value)}
                            />
                        </div>
                    </div>
                    {/* <div className="col-3">
                        <div className="inputBox mb-0">
                            <select className="form-select"  id="gender"
                                name='isSellerAccount'
                                value={isSellerAccount}
                                onChange={(e) => { setIsSellerAccount(e.target.value) }}
                            >
                                <option>Is seller account</option>
                                {
                                    LoadInvestment.map((item, i) => (
                                        <option key={i} value={item.id}>{item.name}</option>
                                    ))
                                }
                                
                                
                            </select>

                        </div>
                    </div> */}
                    <div className="col-8"></div>
                    <div className="col-4">
                        <div className="inputBox mb-3">
                            {!Loading && (
                                <button onClick={() => buttonUpdatePartnerInvestment(idjv)} type="button" className="btn w-100  rounded-pill" style={{ backgroundColor: '#16C0EC' }}>Update Investment</button>
                            )}

                            {Loading && (
                                <button disabled type="button" className="btn w-100 rounded-pill" style={{ backgroundColor: '#16C0EC' }}>
                                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                </button>
                            )}

                        </div>
                    </div>
                </div>
            </Drawer>

            {/**Update  vendor informations modal */}
            <Modal
                title="Upddate Investment Seller"
                open={openModal}
                onOk={handleModal}
                confirmLoading={confirmLoading2}
                onCancel={handleCancelModal}
                footer={false}
                width={1000}
            >
                <div className="row  ">
                    <div className="col-6">

                        <input style={{ display: 'none' }} value={id} onChange={(e) => setId(e.target.value)} type="text" />
                        <div className="inputBox mb-3">
                            <label htmlFor="">Place of Operation</label>
                            <input type="text" className="w-100" placeholder="Place of operation"
                                name="placeOfOperation"
                                onChange={(e) => setPlaceOfOperation(e.target.value)}
                                style={{ color: 'black' }}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="inputBox mb-3">
                            <label htmlFor="">Operation Type</label>
                            <input type="text" className="w-100" placeholder="In office"
                                name="operationLocationType"
                                onChange={(e) => setOperationLocationType(e.target.value)}
                                style={{ color: 'black' }}
                            />
                        </div>
                    </div>

                    <div className="col-8">
                        <div className="inputBox mb-3">
                            <label htmlFor="">Count limit vouchers</label>
                            <input type="number" className="w-100" placeholder="count limit"
                                name="countLimitVouchers"
                                onChange={(e) => setCountLimitVouchers(e.target.value)}
                                style={{ color: 'black' }}
                            />
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="inputBox mb-3">
                            <label htmlFor="">Have licence business</label><br />

                            <Switch
                                //    style={{width:150, height:40, padding:10}}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={false}
                                onChange={handleSwitchChange}
                            />
                        </div>
                    </div>
                    <div className="col-6"></div>

                    <div className="col-6">
                        <div className="inputBox mb-3">
                            {!Loading && (
                                <button onClick={() => UpdateSellerInformation(id)} type="button" className="btn w-100 btn-primary rounded-pill">Update</button>
                            )}

                            {Loading && (
                                <button disabled type="button" className="btn w-100 btn-primary rounded-pill">
                                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                </button>
                            )}

                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Partners