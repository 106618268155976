


import { Card, Select } from "antd"
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import api from '../../../config/Config'
import toast, { Toaster } from 'react-hot-toast';


const Security = () => {

    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);
    const [name, setName] = useState(api.user_Decripter)
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newConfirm, setnewConfirm] = useState('')
    console.log(name)

    useEffect(() => {
        if (localStorage.getItem('onktxonktxwwww') == null) {
            navigate('/login')
        }
    }, []);

    const SecurityInfos = async () => {
        let data = JSON.stringify({
            "name": name,
            "old_password": oldPassword,
            "new_password": newPassword,
            "new_password_confirmation": newConfirm
        });

        setLoading(true);

        if (newPassword != newConfirm) {
            toast.error('The New Password field and the Confirm Password field must be the same');

        } else if (newPassword === '' || newConfirm === '' || name === '' || name === '') {
            toast.error('No field should be empty');
        }
        else {
            api.getHttpRequest("/user/profile/update-security-infos", data, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {

                    toast.success(json.message);
                } else {
                    toast.error(json.message);
                }
            }).catch((error) => {
                // setLoading(false)       
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            })
        }

        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row" >
                    <div>
                        <Toaster
                            position="bottom-left"
                            reverseOrder={false}
                        />
                    </div>
                    <div className="col-3 mb-2">
                        <div className="pagesHeading d-flex">
                            {/* <div className="pageIcon item-center bgProfile">
                     
                                <i className="fas fa-person-booth fa-lg  align-middle me-3" style={{ color: 'white', width: 40, height: 40, borderRadius: 5 }}></i>
                            </div> */}

                            <div className="my-auto ms-1 d-flex">
                                <div>
                                    <span className="tableHeading d-flex position-relative">
                                        My profile
                                        <div className="dropdown categorydrop">
                                            <button className="btn p-0" type="button" id="categoryMenu" data-bs-toggle="dropdown" aria-expanded="false">
                                                {/* <i className="fa-solid fa-caret-down ms-3 fs-5"></i> */}

                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="categoryMenu">
                                                <h5>Security parameters</h5>
                                                {/* <li><Link className="dropdown-item" to="/wallet">Wallet</Link></li>
                                                    <li><Link className="dropdown-item active" to="/investors">Investors</Link></li>
                                                    <li><Link className="dropdown-item" to="/jvpartners">JV Partners</Link></li> */}

                                            </ul>
                                        </div>
                                    </span>
                                </div>


                            </div>

                        </div>
                    </div>
                    <div className="col-1 mb-2"></div>
                    <div className="col-6 mb-2">
                        <div className="d-flex mt-3 justify-content-end" style={{ display: 'flex' }}>

                            {/* <div className="ms-1 mb-1">
                                <button className="btn w-100 " style={{ backgroundColor: '#16C0EC', fontSize: 20 }} >
                                    <PlusOutlined style={{ fontSize: 24 }}
                                    /> Add JV Partners</button>
                            </div> */}




                        </div>

                    </div>
                    <div className="col-1 mb-2"></div>
                </div>

                <div className="row m-0" >

                    <div className="col-12 px-0">
                        <Card style={{ opacity: 0.8 }}>
                            <h4 className="fs-20 text-black pb-2">Update profile

                            </h4>
                            <div className="row  ">
                                <div className="col-6">
                                    <div className="row  ">
                                        <div className="col-6">
                                            <div className="inputBox mb-3">
                                            <label htmlFor="">User name</label>
                                                <input type="text" className="w-100 mt-0" placeholder="Name"
                                                id="name"
                                                    name='name'
                                                    value={name}
                                                    onChange={(e) => { setName(e.target.value) }}

                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="inputBox mb-3">
                                            <label htmlFor="">Old password</label>
                                                <input type="text" className="w-100 mt-0" placeholder="Old password"
                                                   name='oldPassword'
                                                   value={oldPassword}
                                                   onChange={(e) => { setOldPassword(e.target.value) }}

                                                />
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="inputBox mb-3">
                                            <label htmlFor="">New password</label>
                                                <input type="text" className="w-100 mt-0" placeholder="New password"
                                                    name='newConfirm'
                                                    value={newPassword}
                                                    onChange={(e) => { setNewPassword(e.target.value) }}

                                                />
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="inputBox mb-3">
                                            <label htmlFor="">Confirm password</label>
                                                <input type="text" className="w-100 mt-0" placeholder="Confirm password"
                                                    name='newConfirm'
                                                    value={newConfirm}
                                                    onChange={(e) => { setnewConfirm(e.target.value) }}

                                                />
                                            </div>
                                        </div>

                                        <div className="col-6">
                                        <div className="inputBox mb-3">
                                        {!Loading && (
                                            <button onClick={SecurityInfos} type="button" className="btn w-100 btn-primary  rounded-pill" >Update security</button>
                                        )}

                                        {Loading && (
                                            <button disabled type="button" className="btn w-100 btn-primary rounded-pill">
                                                <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                            </button>
                                        )}

                                    </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-6">
                                </div>
                            </div>

                        </Card>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Security