import { Modal } from "antd"
import { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import api from '../../../config/Config'
import { useNavigate } from "react-router-dom";
import RowParams from "../../../controller/requestRowParams";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";

const Mobiles = () => {
    const data = '';
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [prefix, setPrefix] = useState('')
    const [icon, setIcon] = useState('')
    const [id, setId] = useState('')


    //Row params
    const [load, setLoad] = useState<RowParams[]>([])

    useEffect(() => {
        if (localStorage.getItem('onktxonktxwwww') == null) {
            navigate('/login')
        }
        LoadMobileOperator()
    }, []);

    const LoadMobileOperator = async () => {
        api.getHttpRequest("/parameters/mobile-operator/load", data, 'GET').then((response) => {
            const json = response.data;
            setLoad(json.data.rows)

        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    
    const addMobileOperator = async () => {
        const formData = new FormData();

        formData.append("name", name);
        formData.append("prefix", prefix);
        formData.append("icon", icon);

        var config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: api.var_.url_api_local + '/parameters/mobile-operator/create',
            headers: {
                'Authorization': 'Bearer ' + api.initToken(),
                'Content-Type': 'application/json'
            },
            data: formData
        };
        setLoading(true);

        if (name === '' || prefix === '') {
            toast.error('No fields should be empty');
        }
        else {
            axios(config)
                .then(function (response) {
                    const json = response.data;
                    if (json.status == 200) {
                        setName('');
                        setPrefix('');
                        setOpen(false);
                        toast.success(json.message)
                        LoadMobileOperator()
                    } else {
                        toast.error(json.message)
                    }
                })
                .catch((error) => {
                    if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                        toast.error("Please connect your device to the internet");
                    } else if (error.response && error.response.data && error.response.data.message) {
                        toast.error(error.response.data.message);
                    } else {
                        toast.error(error.message);
                    }
                })
        }

        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }

    const UpdateMobileOperator = async (id: any) => {
        const formData = new FormData();

        formData.append("name", name);
        formData.append("prefix", prefix);
        formData.append("icon", icon);

        var config = {
            method: 'PUT',
            maxBodyLength: Infinity,
            url: api.var_.url_api_local + `/parameters/mobile-operator/update/${id}`,
            headers: {
                'Authorization': 'Bearer ' + api.initToken(),
                'Content-Type': 'application/json'
            },
            data: formData
        };
        setLoading(true);

        if (name === '' || prefix === '') {
            toast.error('No fields should be empty');
        }
        else {
            axios(config)
                .then(function (response) {
                    const json = response.data;
                    if (json.status == 200) {
                        setName('');
                        setPrefix('');
                        setOpen2(false);
                        toast.success(json.message)
                        LoadMobileOperator()
                    } else {
                        toast.error(json.message)
                    }
                })
                .catch((error) => {
                    if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                        toast.error("Please connect your device to the internet");
                    } else if (error.response && error.response.data && error.response.data.message) {
                        toast.error(error.response.data.message);
                    } else {
                        toast.error(error.message);
                    }
                })
        }

        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }


    //Modal Request
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [confirmLoading2, setConfirmLoading2] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    function showModalUpdate(id_: any, name_: any, prefixe_: any) {
        setOpen2(true);
        setId(id_)
        setName(name_)
        setPrefix(prefixe_)
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        setOpen(false);
    };
    const handleOk2 = () => {
        setConfirmLoading2(true);
        setTimeout(() => {
            setOpen2(false);
            setConfirmLoading2(false);
        }, 2000);
    };

    const handleCancel2 = () => {
        setOpen2(false);
    };
    return (
        <>
            <div className="row m-0  bg-white profilePage">
                <div className="row" >
                    <div>
                        <Toaster
                            position="bottom-left"
                            reverseOrder={false}
                        />
                    </div>
                    <div className="col-10">
                        <h5 className="fs-5 mb-2">Mobile operator:</h5>
                        <div style={{ display: 'flex', marginLeft: 10, paddingBottom: 10 }}>
                            <label style={{
                                backgroundColor: '#DE0303',
                                height: 20,
                                width: 6,
                                borderRadius: 10
                            }}></label>
                            <strong style={{ paddingLeft: 2 }}>List of mobile operator</strong>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="d-flex mb-2 justify-content-end" style={{ display: 'flex' }}>
                            <button className="btn w-100 " style={{backgroundColor:'#F6EEEE', fontSize:20}} onClick={showModal}>
                                <PlusOutlined  style={{fontSize:24}}  /> mobile</button>
                        </div>

                    </div>
                </div>
                <div className="row m-0">
                    <div className="col-12 px-0">
                        <table className="table secondTable resizable">
                            <thead>
                                <tr>
                                    <th style={{ width: 10, paddingLeft: 15 }}>#</th>
                                    <th >Name</th>
                                    <th >Mobile</th>
                                    <th >createdAt</th>
                                    <th >updatedAt</th>
                                    <th >Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    load.map((item, i) => (
                                        <tr key={i}>
                                            <th style={{ width: 10, paddingLeft: 15 }}>{i + 1}</th>
                                            <td>{item.name}</td>
                                            <td>{item.prefix}</td>
                                            <td>{api.geDateFormat(item.createdAt)}</td>
                                            <td>{api.geDateFormat(item.updatedAt)}</td>

                                            <td>
                                                <div className="d-flex">
                                                    <button className="btn shadow-btn" type="button"
                                                        onClick={() => showModalUpdate(`${item.id}`, `${item.name}`, `${item.prefix}`)}
                                                    ><span>Edit</span><i className="fa-regular fa-pen-to-square"></i></button>
                                                    <button className="btn shadow-btn ms-3"><span>Delete</span><i className="fa-solid fa-trash-can"></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }


                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <Modal
                title="New mobile operator"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={false}
            >
                <>
                    <div className="row  ">
                        <div className="col-12">
                            <div className="inputBox mb-3">
                                <label htmlFor="">Name</label>
                                <input type="text" className="w-100" placeholder=""
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="inputBox mb-3">
                                <label htmlFor="">Prefix</label>
                                <input className="w-100" placeholder="+24000" style={{ color: 'black' }}
                                    name="prefix"
                                    value={prefix}
                                    onChange={(e) => setPrefix(e.target.value)}
                                />
                            </div>
                        </div> 
                        
                        <div className="col-12">
                            <div className="inputBox mb-3">
                                <label htmlFor="">Icon</label>
                                <input type="file" className="w-100" placeholder="" style={{ color: 'black' }}
                                    name="icon"
                                    value={icon}
                                    onChange={(e) => setIcon(e.target.value)}
                                />
                            </div>
                        </div> <hr style={{ marginBottom: 5 }} />
                        <div className="col-12">
                            <div className="inputBox mb-3">
                                {!Loading && (
                                    <button onClick={addMobileOperator} type="button" className="btn w-100 btn-primary rounded-pill">Submit</button>
                                )}

                                {Loading && (
                                    <button disabled type="button" className="btn w-100 btn-primary rounded-pill">
                                        <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                    </button>
                                )}

                            </div>
                        </div>
                    </div>
                </>
            </Modal>

            <Modal
                title="Upddate mobile operator"
                open={open2}
                onOk={handleOk2}
                confirmLoading={confirmLoading2}
                onCancel={handleCancel2}
                footer={false}
            >
                <>
                    <div className="row  ">
                        <div className="col-12">
                            <input style={{ display: 'none' }} value={id} onChange={(e) => setId(e.target.value)} type="text" />
                            <div className="inputBox mb-3">
                                <label htmlFor="">Name</label>
                                <input type="text" className="w-100" placeholder=""
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    style={{ color: 'black' }}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="inputBox mb-3">
                                <label htmlFor="">Prefix</label>
                                <input className="w-100" placeholder="+24000" style={{ color: 'black' }}
                                    name="prefix"
                                    value={prefix}
                                    onChange={(e) => setPrefix(e.target.value)}
                                />
                            </div>
                        </div> 
                        
                        <div className="col-12">
                            <div className="inputBox mb-3">
                                <label htmlFor="">Icon</label>
                                <input type="file" className="w-100" placeholder="" style={{ color: 'black' }}
                                    name="icon"
                                    value={icon}
                                    onChange={(e) => setIcon(e.target.value)}
                                />
                            </div>
                        </div><hr style={{ marginBottom: 5 }} />
                        <div className="col-12">
                            <div className="inputBox mb-3">
                                {!Loading && (
                                    <button onClick={() => UpdateMobileOperator(id)} type="button" className="btn w-100 btn-primary rounded-pill">Update</button>
                                )}

                                {Loading && (
                                    <button disabled type="button" className="btn w-100 btn-primary rounded-pill">
                                        <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                    </button>
                                )}

                            </div>
                        </div>
                    </div>
                </>
            </Modal>
        </>
    )
}

export default Mobiles