import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import api from '../../../config/Config'
import CryptoJS from 'crypto-js';
import "../../../components/assets/css1/css/app.css";
import "../../../components/assets/css1/css/bootstrap-grid.min.css";
import "../../../components/assets/css1/icons/boxicons-2/css/boxicons.min.css";

type Code = {
    email_or_phone: string;
    code_val: string
}

const Verify = () => {

    const navigate = useNavigate()

    // Définir la clé de chiffrement
    const key = 'phone_account';

    // Récupérer la variable chiffrée depuis le local storage
    const phone_Cripter = localStorage.getItem('zxremonailnephoypnmal');

    // Décrypter la variable
    let phone_Decripter: any;
    if (phone_Cripter !== null) {
        const bytes = CryptoJS.AES.decrypt(phone_Cripter, key);
        phone_Decripter = bytes.toString(CryptoJS.enc.Utf8);
    } else {
        phone_Decripter = null;
    }

    const [Loading, setLoading] = useState(false);

    const [valcode, setValcode] = useState<Code>({
        email_or_phone: "",
        code_val: ""
    })

    const setNewValue = (id_: string, newValue: string) =>
        setValcode(prevState => ({ ...prevState, [id_]: newValue }))

    {/** Button validation code */ }
    const btnValidatecode = async () => {

        let data = JSON.stringify({
            "code_val": valcode.code_val,
            "email_or_phone": phone_Decripter
        });

        setLoading(true);
        if (valcode.code_val === '') {
            toast.error('The Code field must not be empty')
        }
        else {
            api.getHttpRequestNotToken("/user/forgot-password/verify", data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    toast.success(json.message)
                    navigate('/login')
                } else {
                    toast.error(json.message)
                }
            }).catch((error) => {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            })
        }
        setTimeout(() => {
            setLoading(false);
        }, 5000)
    }

    {/**Resend Password */ }
    const ResendPaswd = async () => {

        let data = JSON.stringify({
            "email_or_phone": phone_Decripter
        });

        api.getHttpRequestNotToken("/user/forgot-password/start", data, 'POST').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                toast.success(json.message)
            } else {
                toast.error(json.message)
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("No internet connection");
            } else {
                toast.error(error.response.data.message);
            }
        })
    }


    return (
        <>
            <div className="auth-multi-layout">
                <div className="auth-box">
                    <div className="auth-header">
                        <div className="auth-header-logo forgot-img">
                            <img src="" alt="" className="auth-header-logo-img" />
                        </div>
                    </div>
                    <div className="auth-body">
                        <h1 className="auth-header-title">Verify Password?</h1>
                        <p className="auth-header-subtitle forgot-subtitle">
                            Enter your email and we'll send you intructions to reset your password
                        </p>
                        <form className="auth-form-validation">
                            <div className="input-field">
                                <label htmlFor="email" className="input-label">
                                    Email address
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    placeholder="example@gmail.com"
                                    autoComplete="off"
                                    required
                                    name='code_val'
                                    value={valcode.code_val}
                                    onChange={(e) => { setNewValue('code_val', e.target.value) }}
                                />
                            </div>
                            {!Loading && (
                                <button type="submit" className="btn-submit" onClick={btnValidatecode}>
                                    Validate
                                </button>
                            )}

                            {Loading && (

                                <button type="submit" className="btn-submit" disabled>
                                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                </button>
                            )}
                            <Link to={"/login"} className="btn-back-to-login">
                                Back to login
                            </Link>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Verify