import { HashRouter, Routes, Route} from 'react-router-dom';
import Login from './views/authentificate/login';
import Verify from './views/authentificate/forgetpass/VerifyOtp';
import ForgotPass from './views/authentificate/forgetpass';
import Container from './components/container';
import './components/assets/css/bootstrap.min.css';
import './components/assets/css/amsify.select.css';
import './components/assets/css/style.css';
import './components/assets/css/color.css';


function App() {
  return (
    <>
       <HashRouter>
          <Routes>
          <Route index element={<Login />} />
          <Route path='/login' element={<Login />} />
          <Route path='/forgot_pswd' element={<ForgotPass />} />
              <Route path='/verify_forgot' element={<Verify/>} />
           <Route path='*' element={<Container />} />
          </Routes>
       </HashRouter>
    </>
  );
}

export default App;
