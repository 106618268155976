import { CaretDownOutlined, HddOutlined, SettingOutlined } from "@ant-design/icons"
import { Card, Table, Tabs, TableColumnsType, Row, Space, Statistic, Typography } from "antd"
import { useEffect, useState } from "react"
import toast, { Toaster } from 'react-hot-toast';
import api from '../../config/Config'
import requestUssd from "../../controller/requestUssd";
import { ExpandableConfig } from 'antd/lib/table/interface';
const { Title } = Typography;

const Ussd = () => {
    const data = '';
    const [Loading, setLoading] = useState(false)
    const [loadInfo, setLoadInfo] = useState<requestUssd[]>([])
    const [countSessions, setCountSessions] = useState('')
    const [countSessionError, setCountSessionError] = useState('')
    const [countSessionSuccess, setCountSessionSuccess] = useState('')
    const [countByTelecom, setCountByTelecom] = useState<requestUssd[]>([])
    const [totalPage, setTotalPage] = useState(1)

    useEffect(() => {
        LoadSessionUssd(1)
    }, [])

    const LoadSessionUssd = async (page: any) => {
        setLoading(true);
        api.getHttpRequest(`/ussd/load?page=${page}`, data, 'GET').then((response) => {
            const json = response.data;
            setLoadInfo(json.data.sessionData)
            setCountSessions(json.data.count_sessions)
            setCountSessionError(json.data.count_session_error)
            setCountSessionSuccess(json.data.count_session_success)
            setCountByTelecom(json.data.count_by_telecom)
            setTotalPage(json.data.count_sessions)
            setLoading(false)
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const dataUssd = loadInfo.map((item, i) => ({
        ...item,
        key: i,

    }))

    const LoadTableUssd: TableColumnsType<requestUssd> = [

        {
            title: 'Session/Id',
            dataIndex: 'sessionId'
        },
        {
            title: 'Name user',
            dataIndex: 'name_user'
        },
        {
            title: 'Mobile Operator',
            dataIndex: 'mobile_operator'
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            render: (createdAt) => api.geDateFormat(createdAt)
        },
        {
            title: 'Msisdn',
            dataIndex: 'msisdn'
        },

        {
            title: 'Status',
            dataIndex: 'status',
            render: (status, record) => {
                if (status === 1) {
                    return (
                        <span style={{ backgroundColor: '#48BD89', color: 'white', padding: 5, borderRadius: 5, marginRight: 5 }}>
                            Success <br />

                        </span>
                    )
                } else if (status === 0) {
                    return (
                        <span style={{ backgroundColor: '#FF4D4F', color: 'black', padding: 5, borderRadius: 5, marginRight: 5 }}>
                            Error <br />

                        </span>
                    )
                }
            }
        },

        {
            title: 'Diff in seconds',
            dataIndex: 'diff_in_seconds',
            key: 'diff_in_seconds',
            render: (text) => text === null ? '0 seconds' : `${text} seconds`,
        }

    ]

    const LoadTableUssd2: TableColumnsType<requestUssd> = [
        {
            title: 'Input',
            dataIndex: 'input'
        },
        {
            title: 'Request',
            dataIndex: 'newRequest',
            render: (newRequest, record) => {
                if (newRequest === 1) {
                    return (
                        <span style={{ backgroundColor: '#4096FF', color: 'black', padding: 5, borderRadius: 5, marginRight: 5 }}>
                            NewRequest <br />

                        </span>
                    )
                } else if (newRequest === 0) {
                    return (
                        <span style={{ backgroundColor: '#FFF2F0', color: 'black', padding: 5, borderRadius: 5, marginRight: 5 }}>
                            Continue <br />

                        </span>
                    )
                }
            }
        },
        {
            title: 'FinalisedAt',
            dataIndex: 'requestFinalisedAt',
            render: (requestFinalisedAt) => api.geDateFormat(requestFinalisedAt)
        },
        {
            title: 'Response',
            dataIndex: 'response'
        },
    ]

    const expandableConfig: ExpandableConfig<requestUssd> & { rowKey: string } = {
        rowExpandable: (record) => true,
        expandedRowRender: (record) => {
            const filteredData = dataUssd.filter((item) => item.id === record.id);
            return <Table columns={LoadTableUssd2} dataSource={filteredData} pagination={false} className="tableDrop"></Table>;
        },
        rowKey: 'sessionId'
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row" >
                    <div className="col-1 mb-2">
                        <div className="pagesHeading ">
                            {/* <div className="pageIcon item-center bgProfile">
                     
                                <i className="fas fa-person-booth fa-lg  align-middle me-3" style={{ color: 'white', width: 40, height: 40, borderRadius: 5 }}></i>
                            </div> */}

                            <div className="my-auto ms-1 d-flex">
                                <div>
                                    {/* <span className="small-font">Ussd</span> */}
                                    <span className="tableHeading d-flex position-relative">
                                        <SettingOutlined style={{ fontSize: 24, }} /> USSD
                                        <div className="dropdown categorydrop">
                                            <button className="btn p-0" type="button" id="categoryMenu" data-bs-toggle="dropdown" aria-expanded="false">
                                                {/* <i className="fa-solid fa-caret-down ms-3 fs-5"></i> */}
                                                <CaretDownOutlined className="ms-3 fs-5" style={{ color: 'black' }} />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="categoryMenu">
                                                <h5>Ussd session</h5>
                                                {/* <li><Link className="dropdown-item" to="/wallet">Wallet</Link></li>
                                                    <li><Link className="dropdown-item active" to="/investors">Investors</Link></li>
                                                    <li><Link className="dropdown-item" to="/jvpartners">JV Partners</Link></li> */}

                                            </ul>
                                        </div>
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div className="">
                            <div className="d-flex mt-3 justify-content-left" >

                                {/* <div className="ms-1 mb-1">
                                <button className="btn w-100 " style={{ backgroundColor: '#16C0EC', fontSize: 20 }} >
                                    <PlusOutlined style={{ fontSize: 24 }}
                                    /> Add JV Partners</button>
                            </div> */}


                                <div className="ms-1 justify-content-end" style={{ display: 'flex' }}>

                                    <TransactionCard gb="#1576ED" color="black"
                                        title={"Total"} value={countSessions} />

                                </div>
                                <div className="ms-1 justify-content-end" style={{ display: 'flex' }}>

                                    <TransactionCard bg="red" color="white"
                                        title={"Error"} value={countSessionError} />

                                </div>
                                <div className="ms-1 justify-content-end" style={{ display: 'flex' }}>

                                    <TransactionCard bg="#48BD89" color="white"
                                        title={"Success"} value={countSessionSuccess} />

                                </div>
                                {
                                    countByTelecom.map((item, i) => (
                                        <div className="ms-1 justify-content-end" style={{ display: 'flex' }}>

                                            <TransactionCard bg="Success" color="red"
                                                title={item.mobile_operator} value={item.nombre} />

                                        </div>
                                    ))
                                }


                            </div>

                        </div>
                    </div>

                    {/* <div className="col-1 mb-2"></div> */}

                </div>

                <div className="row m-0">
                    <div className="col-12 px-0">

                        <Card style={{ opacity: 0.9, width: '100%' }}>
                            <div className="row" >
                                <div>
                                    <Toaster
                                        position="bottom-left"
                                        reverseOrder={false}
                                    />
                                </div>
                                <div className="col-10">
                                    {/* <h5 className="fs-5 mb-2">Manage use account:</h5> */}
                                    <div style={{ display: 'flex', marginLeft: 10, paddingBottom: 10 }}>
                                        <label style={{
                                            backgroundColor: '#4920E9',
                                            height: 20,
                                            width: 6,
                                            borderRadius: 10
                                        }}></label>
                                        <strong style={{ paddingLeft: 2 }}>List of ussd session</strong>
                                    </div>
                                </div>
                                {/* <div className="col-2">
                        <div className="d-flex mb-2 justify-content-end" style={{ display: 'flex' }}>
                            <button className="btn w-100 btn-primary " >New roles</button>
                        </div>

                    </div> */}
                            </div>

                            <Table
                                columns={LoadTableUssd}
                                dataSource={dataUssd}
                                size="small"
                                expandable={expandableConfig}
                                loading={Loading}
                                pagination={{
                                    pageSize: 10,
                                    total: totalPage,
                                    onChange: (page: any) => {
                                        LoadSessionUssd(page);
                                    }
                                }}
                            />



                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

function TransactionCard({ title, value, color, bg }: any) {
    return (
        <Card
            hoverable
            style={{ width: 210, backgroundColor: bg, boxSizing: 'border-box', boxShadow: "none", }}
            bodyStyle={{ display: 'table',}}
        >

            

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span style={{ fontSize: '20px', color: color, paddingTop:0, marginRight:10 }}>{title}</span>
                <span style={{ color: color, fontSize: '20px', paddingTop:0, }}>{value}</span>

            </div>
        </Card>
    )
}

export default Ussd