import { Link, useNavigate } from "react-router-dom"
import { Card, Tabs } from "antd"
import { CaretDownOutlined, SettingOutlined } from "@ant-design/icons"
import Roles from "./roles"
import InvestmentCategory from "./investmentcategory"
import NewConstant from "./constant"
import Mobiles from "./mobile"
import Country from "./countries"
import UserAccount from "./useraccount"
import { useEffect } from "react"


const Settings = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        if (localStorage.getItem('onktxonktxwwww') == null) {
            navigate('/login')
        }
    }, [])
    return (
        <>
            <div className="container-fluid">

                <div className="row" >
                    <div className="col-5 mb-2">
                        <div className="pagesHeading d-flex">
                            {/* <div className="pageIcon item-center bgProfile">
                     
                                <i className="fas fa-person-booth fa-lg  align-middle me-3" style={{ color: 'white', width: 40, height: 40, borderRadius: 5 }}></i>
                            </div> */}
                            
                            <div className="my-auto ms-1 d-flex">
                                <div>
                                    <span className="small-font">Settings</span>
                                    <span className="tableHeading d-flex position-relative">
                                    <SettingOutlined style={{fontSize:24}} /> Our Settings
                                        <div className="dropdown categorydrop">
                                            <button className="btn p-0" type="button" id="categoryMenu" data-bs-toggle="dropdown" aria-expanded="false">
                                                {/* <i className="fa-solid fa-caret-down ms-3 fs-5"></i> */}
                                                <CaretDownOutlined className="ms-3 fs-5" style={{ color: 'black' }} />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="categoryMenu">
                                                <h5>Sellers Categories</h5>
                                                {/* <li><Link className="dropdown-item" to="/wallet">Wallet</Link></li>
                                                    <li><Link className="dropdown-item active" to="/investors">Investors</Link></li>
                                                    <li><Link className="dropdown-item" to="/jvpartners">JV Partners</Link></li> */}

                                            </ul>
                                        </div>
                                    </span>
                                </div>
                                <div className="mt-3 ms-4">
                                
                                    <button className="btn shadow-btn iconBtn item-center bg-white"><img src="../assets/img/pin.svg" alt="" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-7">
                        <div className="d-flex mt-3 justify-content-end" style={{ display: 'flex' }}>
                            <div className="ms-1">
                                <button style={{ backgroundColor: "#2B6279", color: "white" }} className="btn shadow-btn h-100 ">send-all-payement</button>
                            </div>
                            <div className="ms-1">
                                <button style={{ backgroundColor: "#2B6279", color: "white" }} className="btn shadow-btn h-100 ">Total Roles</button>
                            </div>
                        </div>

                    </div> */}
                </div>

                <div className="row m-0">
                    <div className="col-12 px-0">
                        <Card style={{ opacity: 0.9 }}>
                            <Tabs defaultActiveKey="tab1" tabPosition="top" >

                                <Tabs.TabPane style={{fontSize:20}} tab="Investment categories" key="tab1">

                                    <InvestmentCategory/>

                                </Tabs.TabPane>

                                <Tabs.TabPane tab="Attribution roles" key="tab2">

                                    <Roles />

                                </Tabs.TabPane>

                                <Tabs.TabPane tab="Constant" key="tab3">

                                    <NewConstant/>

                                </Tabs.TabPane>

                                <Tabs.TabPane tab="Mobile operator" key="tab4">

                                    <Mobiles/>

                                </Tabs.TabPane>

                                <Tabs.TabPane tab="Add Zones" key="tab5">

                                    <Country/>

                                </Tabs.TabPane>

                                <Tabs.TabPane tab="Manage user account" key="tab6">
                                   <UserAccount/>
                                </Tabs.TabPane>


                                {/* <Tabs.TabPane tab="User Registration" key="tab3">

                    <User/>

                  </Tabs.TabPane> */}

                            </Tabs>

                          
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Settings