
import { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import api from '../../../../config/Config'
import { useNavigate } from "react-router-dom";
import RowParams from "../../../../controller/requestRowParams";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Popconfirm, Select } from "antd"
const { Option } = Select;

const Minicipality = () => {
    const data = '';
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('')
    const [id, setId] = useState('')
    const [idCity, setIdCity] = useState('')
    const [minicipal, setMinicipal] = useState('');

    //Row params
    const [load, setLoad] = useState<RowParams[]>([])

    // Variables Load elements
    const [province, setPronvince] = useState<RowParams[]>([])
    const [city, setCity] = useState<RowParams[]>([])
    const [minicipality, setMinicipality] = useState<RowParams[]>([])

    useEffect(() => {
        if (localStorage.getItem('onktxonktxwwww') == null) {
            navigate('/login')
        }
        loadProvince()
        loadMinicipality()
        loadCity()
    }, []);

    //Load zones
    const loadProvince = async () => {
        api.getHttpRequest("/zone/province/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setPronvince(json.data);
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const loadCity = async () => {
        api.getHttpRequest("/zone/city/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setCity(json.data);
            }
        }).catch((error) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const loadMinicipality = async () => {
        api.getHttpRequest("/zone/municipality/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setLoad(json.data);
            }
        }).catch((error) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }


    const addMinicipality = async () => {
        let data = JSON.stringify({
            "name": name,
            "description": description,
            "id_city": idCity
        });

        setLoading(true);

        if (name === '' || description === '') {
            toast.error('The name and description fields must not be empty');

        } else {
            api.getHttpRequest("/zone/municipality/create", data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 201) {
                    setName('');
                    setDescription('');
                    setOpen(false);
                    toast.success(json.message);
                    loadMinicipality()
                } else {
                    toast.error(json.message);
                }
            }).catch((error) => {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            })

        }

        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }

    const UpdateMinicipality = async (id: any) => {
        let data = JSON.stringify({
            "name": name,
            "description": description,
            "id_city": idCity
        });

        setLoading(true);

        if (name === '' || description === '') {
            toast.error('The name and description fields must not be empty');

        } else {
            api.getHttpRequest(`/zone/municipality/update/${id}`, data, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    setName('');
                    setDescription('');
                    setOpen2(false);
                    toast.success(json.message);
                    loadMinicipality()
                } else {
                    toast.error(json.message);
                }
            }).catch((error) => {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            })

        }

        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }

    const DeleteCity = (value: any) => {
        api.getHttpRequest(`/zone/municipality/delete/${value}`, data, 'delete').then((response) => {
            if (response.data.status == 201) {
                toast.success(response.data.message);
                setLoading(false);
                loadMinicipality()
            }
            else {
                setLoading(false);
                toast.error(response.data.message);
            }
        }).catch((error) => {
            setLoading(false);
            toast.error(error.message);
        })

    }


    //Modal Request
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [confirmLoading2, setConfirmLoading2] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    function showModalUpdate(id_: any, name_: any, description_: any, id_ward: any) {
        setOpen2(true);
        setId(id_)
        setName(name_)
        setDescription(description_)
        // setIdCity(id_ward)
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        setOpen(false);
    };
    const handleOk2 = () => {
        setConfirmLoading2(true);
        setTimeout(() => {
            setOpen2(false);
            setConfirmLoading2(false);
        }, 2000);
    };

    const handleCancel2 = () => {
        setOpen2(false);
    };

    // Fonction qui va permettre des charger le city appartir de la province
    const [stateCity, setStateCity] = useState<RowParams[]>([])
    const [stateMinicipality, setStateMinicipality] = useState<RowParams[]>([])
    const GetProvince = (id: any) => {
        const IdProv = city.filter(x => x.PronvinceId === id);
        setStateCity(IdProv)
    }

    const GetCity = (id: any) => {
        const IdCity = minicipality.filter(x => x.CityId === id);
        setStateMinicipality(IdCity)
    }


    return (
        <>
            <div className="row m-0  bg-white profilePage">
                <div className="row" >
                    <div>
                        <Toaster
                            position="bottom-left"
                            reverseOrder={false}
                        />
                    </div>
                    <div className="col-10">
                        {/* <h5 className="fs-5 mb-2">New province:</h5> */}
                        <div style={{ display: 'flex', marginLeft: 2, paddingBottom: 2 }}>
                            <label style={{
                                backgroundColor: '#DE0303',
                                height: 25,
                                width: 6,
                                borderRadius: 10
                            }}></label>
                            <strong style={{ paddingLeft: 2 }}>List of Wards</strong>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="d-flex mb-2 justify-content-end" style={{ display: 'flex' }}>
                            <button className="btn w-100 " style={{ backgroundColor: '#F6EEEE', fontSize: 16 }} onClick={showModal}>
                                <PlusOutlined style={{ fontSize: 16, marginTop: 0 }} />Wards</button>
                        </div>

                    </div>
                </div>
                <div className="row m-0">
                    <div className="col-12 px-0">
                        <table className="table secondTable resizable">
                            <thead>
                                <tr>
                                    <th style={{ width: 10, paddingLeft: 15 }}>#</th>
                                    <th >Name</th>
                                    <th >Description</th>
                                    <th >createdAt</th>
                                    <th >updatedAt</th>
                                    <th >Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    load.map((item, i) => (
                                        <tr key={i}>
                                            <th style={{ width: 10, paddingLeft: 15 }}>{i + 1}</th>
                                            <td>{item.name}</td>
                                            <td>{item.description}</td>
                                            <td>{api.geDateFormat(item.createdAt)}</td>
                                            <td>{api.geDateFormat(item.updatedAt)}</td>

                                            <td>
                                                <div className="d-flex">
                                                    <button className="btn shadow-btn" type="button"
                                                        onClick={() => showModalUpdate(`${item.id}`, `${item.name}`, `${item.description}`, `${item.CityId}`)}
                                                    ><span>Edit</span><i className="fa-regular fa-pen-to-square"></i></button>
                                                    <Popconfirm title={`Are you sure want to delete ${item.name} ?`} onConfirm={() => DeleteCity(item.id)}>
                                                        <button className="btn shadow-btn ms-3"><span>Delete</span><i className="fa-solid fa-trash-can"></i></button>
                                                    </Popconfirm>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }


                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <Modal
                title="New Wards"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={false}
                width={1000}
            >
                <>

                    <div className="col-12 mb-3">
                        <div className="row  ">

                            <div className="col-6">
                                <label className="form-label">Region</label>

                                <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}

                                    placeholder="Select country" optionFilterProp="children" onChange={GetProvince}
                                    filterOption={(input: string, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Option selected value="">Select State/Region
                                    </Option>
                                    {
                                        province &&
                                            province !== undefined ?
                                            province.map((item, i) => (
                                                <Option key={i} value={item.id}>{item.name}</Option>
                                            ))
                                            : "No Province"
                                    }
                                </Select>
                            </div>
                            <div className="col-6">
                                <label className="form-label">District</label>

                                <Select showSearch style={{ width: "100%", color: 'black' }} dropdownStyle={{ height: 200 }}

                                    placeholder="Select country" optionFilterProp="children" value={idCity} onChange={(e) => { setIdCity(e) }}
                                    filterOption={(input: string, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option selected value="">Select District
                                    </Option>
                                    {
                                        stateCity &&
                                            stateCity !== undefined || stateCity !== null ?
                                            stateCity.map((item, i) => (
                                                <Option key={i} value={item.id}>{item.name}</Option>
                                            ))
                                            : "No City"
                                    }
                                </Select>
                            </div>

                        </div>
                    </div>

                    <div className="col-12">
                        <input style={{ display: 'none' }} value={id} onChange={(e) => setId(e.target.value)} type="text" />
                        <div className="inputBox mb-3">
                            <label htmlFor="">Wards/Name</label>
                            <input type="text" className="w-100" placeholder=""
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                style={{ color: 'black' }}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="inputBox mb-3">
                            <label htmlFor="">Wards/Description</label>
                            <textarea className="w-100" placeholder="" style={{ color: 'black' }}
                                name="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                    </div>
                    
                      <hr style={{ marginBottom: 5 }} />
                    <div className="col-12">
                        <div className="inputBox mb-3">
                            {!Loading && (
                                <button onClick={addMinicipality} type="button" className="btn w-100 btn-primary rounded-pill">Submit</button>
                            )}

                            {Loading && (
                                <button disabled type="button" className="btn w-100 btn-primary rounded-pill">
                                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                </button>
                            )}

                        </div>
                    </div>

                </>
            </Modal>

            <Modal
                title="Upddate Wards"
                open={open2}
                onOk={handleOk2}
                confirmLoading={confirmLoading2}
                onCancel={handleCancel2}
                footer={false}
                width={1000}
            >
                <>

                    <div className="col-12">
                        <div className="row  ">
                            <div className="col-6">
                                <label className="form-label">Region</label>

                                <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}

                                    placeholder="Select country" optionFilterProp="children" onChange={GetProvince}
                                    filterOption={(input: string, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Option selected value="">Select/Region
                                    </Option>
                                    {
                                        province &&
                                            province !== undefined ?
                                            province.map((item, i) => (
                                                <Option key={i} value={item.id}>{item.name}</Option>
                                            ))
                                            : "No Province"
                                    }
                                </Select>
                            </div>
                            <div className="col-6">
                                <label className="form-label">District</label>

                                <Select showSearch style={{ width: "100%", color: 'black' }} dropdownStyle={{ height: 200 }}

                                    placeholder="Select country" optionFilterProp="children" value={idCity} onChange={(e) => { setIdCity(e) }}
                                    filterOption={(input: string, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option selected value="">Select/District
                                    </Option>
                                    {
                                        stateCity &&
                                            stateCity !== undefined || stateCity !== null ?
                                            stateCity.map((item, i) => (
                                                <Option key={i} value={item.id}>{item.name}</Option>
                                            ))
                                            : "No City"
                                    }
                                </Select>
                            </div>
                        </div>


                    </div>

                    <div className="col-12">
                        <input style={{ display: 'none' }} value={id} onChange={(e) => setId(e.target.value)} type="text" />
                        <div className="inputBox mb-3">
                            <label htmlFor="">Wards/Name</label>
                            <input type="text" className="w-100" placeholder=""
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                style={{ color: 'black' }}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="inputBox mb-3">
                            <label htmlFor="">Municipality/Desciption</label>
                            <textarea className="w-100" placeholder="" style={{ color: 'black' }}
                                name="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                    </div><hr style={{ marginBottom: 5 }} />
                    <div className="col-12">
                        <div className="inputBox mb-3">
                            {!Loading && (
                                <button onClick={() => UpdateMinicipality(id)} type="button" className="btn w-100 btn-primary rounded-pill">Update</button>
                            )}

                            {Loading && (
                                <button disabled type="button" className="btn w-100 btn-primary rounded-pill">
                                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                </button>
                            )}

                        </div>
                    </div>

                </>
            </Modal>
        </>
    )
}

export default Minicipality