import { CaretDownOutlined, DeliveredProcedureOutlined } from "@ant-design/icons"
import {
    Card,
    Col, Input, Modal, Row, Badge, Dropdown, Space, Table,
    TableColumnsType,
    Popconfirm,
    Button,
    Typography,
    Drawer,
    DrawerProps
} from "antd"
import toast, { Toaster } from 'react-hot-toast';
import api from '../../config/Config'
import { useEffect, useState } from "react";
import requestVoucher from "../../controller/requestVoucher";
import { useNavigate } from "react-router-dom";

const Vouchers = () => {
    const data = '';
    const navigate = useNavigate();

    const [Loading, setLoading] = useState(false);
    const [Loading1, setLoading1] = useState(false);
    const [Loading2, setLoading2] = useState(false);

    const [totalPage, setTotalPage] = useState(1);
    const [totalPage1, setTotalPage1] = useState(1);
    const [totalPage2, setTotalPage2] = useState(1);
    const [TotalVoucher, setTotalVoucher] = useState(0)
    const [TotalSecondary, setTotalSecondary] = useState(0)
    const [totalSerie, setTotalSerie] = useState(0)
    const [listVoucher, setlistVoucher] = useState<requestVoucher[]>([]);
    const [listSecondary, setListSecondary] = useState<requestVoucher[]>([]);
    const [listVoucherByIdSecondary, setListVoucherByIdSecondary] = useState<requestVoucher[]>([]);
    const [valueserie, setValueserie] = useState(0)
    const [valueVoucher, setValueVoucher] = useState(0)

    useEffect(() => {
        if (localStorage.getItem('onktxonktxwwww') == null) {
            navigate('/login')
        }
        LoadSeriePrincipal(1)
    }, [])

    const LoadSeriePrincipal = async (page: any) => {
        setLoading(true);
        api.getHttpRequest(`/vouchers/load-series?page=${page}`, data, 'GET').then((response) => {
            const json = response.data;
            setlistVoucher(json.data.rows)
            setTotalPage(json.data.count);
            setTotalSerie(json.data.count);
            setLoading(false);
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const LoadSerieSecondary = (id: any, page: any) => {
        setLoading1(true);
        setValueserie(id)
        api.getHttpRequest(`/vouchers/load-secondary/${id}?page=${page}`, data, 'GET').then((response) => {
            const result = response.data.data
            setListSecondary(result.rows);
            // setSearchResult(result.rows);
            setTotalPage1(result.count);
            setTotalSecondary(result.count);
            setLoading1(false);
        }).catch((error) => {
            toast.error(error.message);
        })
    }

    const LoadVoucherByIdSecondary = (id: any, page: any) => {
        setLoading2(true);
        setValueVoucher(id)
        api.getHttpRequest(`/vouchers/load/${id}?page=${page}`, data, 'GET').then((response) => {
            const result = response.data.data
            setListVoucherByIdSecondary(result.rows);
            // setSearchResult(result.rows);
            setTotalPage1(result.count);
            setTotalVoucher(result.count);
            setLoading2(false);
        }).catch((error) => {
            toast.error(error.message);
        })
    }

    {/**Load serie principal */ }
    const dataVoucher = listVoucher.map((item, i) => ({
        ...item,
        key: i
    }))

    const columnsSerie: TableColumnsType<requestVoucher> = [

        {
            title: "#",
            dataIndex: "id",
        },
        {
            title: "Serie",
            dataIndex: "serie",
            align: "center",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            align: "center",
        },
        {
            title: "Currency",
            dataIndex: "currency",
            align: "center",
        },
        // {
        // 	title: "Date of creation",
        // 	dataIndex: "createdAt",
        // 	align: "center",
        // },
        // {
        // 	title: "Expiry date",
        // 	dataIndex: "expireAt",
        // 	align: "center",
        // },
        {
            title: "Action",
            dataIndex: "action",
            align: "center",
            render: (_, record) =>
                dataVoucher.length >= 1 ? (
                    <Space>
                        {/* <Button onClick={() => navigate(`/secondary/${record.id}`)}><i className="fas fa-eye"></i></Button> */}
                        <Button onClick={() => LoadSerieSecondary(record.id, 1)}><i className="fas fa-eye"></i></Button>

                        {/* <Popconfirm title="Are you sure want to delete ?" onConfirm={() => handleDelete(record)}>
							<DeleteOutlined style={{
								paddingTop: 1, color: 'white', backgroundColor: 'red',
								borderRadius: 10,
								fontSize: 10,
								padding: 8
							}} />
						</Popconfirm> */}
                    </Space>

                ) : null
        },
    ]


    {/**load serie secondary */ }
    const dataSecondary = listSecondary.map((item, i) => ({
        ...item,
        key: i,
        details: `Date of creation ${api.geDateFormat(item.createdAt)}`
    }))

    const columnsSecondary: TableColumnsType<requestVoucher> = [
        {
            title: "Id",
            dataIndex: "id",
        },
        {
            title: "Serie",
            dataIndex: "serie",
            align: "center",
        },
        {
            title: "Packet",
            dataIndex: "number_by_packet",
            align: "center",
        },

        {
            title: "Serie Id",
            dataIndex: "vouchersSeryId",
            align: "center",
        },

        {
            title: "Action",
            dataIndex: "action",
            align: "center",
            render: (_, record) =>
                dataVoucher.length >= 1 ? (
                    <Space>
                        <Button onClick={() => LoadVoucherByIdSecondary(record.id, 1)}><i className="fas fa-eye"></i></Button>
                        {/* <Popconfirm title="Are you sure want to delete ?" onConfirm={() => handleDelete(record)}>
							<Button danger type='primary'>Delete</Button>
						</Popconfirm> */}
                    </Space>

                ) : null
        },
    ]

    {/** Load voucher by id secondary */ }

    const getShortVouch = (r: any) => {
        return '**********' + r.substring((r.length - 4), r.length)
    }

    const dataVoucherByIdSecondary = listVoucherByIdSecondary.map((item, i) => ({
        ...item,
        key: i,
        // details: `Date of creation ${api.geDateFormat(item.createdAt)}`
    }))


    const columnsVoucher_Attribute: TableColumnsType<requestVoucher> = [
        {
            title: "#",
            dataIndex: "id",
        },
        { title: "Pin", dataIndex: "pin", align: "center", render: (pin) => getShortVouch(pin.toString()) },
        {
            title: "Used",
            dataIndex: "used",
            align: "center",
            render: (used) => {
                if (used) {
                    return (<button style={{ backgroundColor: "red", borderColor: "red", color: "#ffffff" }}> is used </button>)
                }
                else { return (<span style={{ backgroundColor: "#F6EEEE", borderColor: "#F6EEEE", color: "black", padding: 5 }}> Not used </span>) }
            }
        },
    ]

    return (
        <>
            <div className="container-fluid">
                <div className="row" >
                    <div>
                        <Toaster
                            position="bottom-left"
                            reverseOrder={false}
                        />
                    </div>
                    <div className="col-1 mb-2">
                        <div className="pagesHeading d-flex">
                            {/* <div className="pageIcon item-center bgProfile">
                     
                                <i className="fas fa-person-booth fa-lg  align-middle me-3" style={{ color: 'white', width: 40, height: 40, borderRadius: 5 }}></i>
                            </div> */}

                            <div className="my-auto ms-1 d-flex">
                                <div>
                                    <span className="small-font">Vouchers</span>
                                    <span className="tableHeading d-flex position-relative">
                                        <DeliveredProcedureOutlined style={{ fontSize: 24 }} /> Vouchers
                                        <div className="dropdown categorydrop">
                                            <button className="btn p-0" type="button" id="categoryMenu" data-bs-toggle="dropdown" aria-expanded="false">
                                                {/* <i className="fa-solid fa-caret-down ms-3 fs-5"></i> */}
                                                <CaretDownOutlined className="ms-3 fs-5" style={{ color: 'black' }} />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="categoryMenu">
                                                <h5>Sellers Categories</h5>
                                                {/* <li><Link className="dropdown-item" to="/wallet">Wallet</Link></li>
                                                    <li><Link className="dropdown-item active" to="/investors">Investors</Link></li>
                                                    <li><Link className="dropdown-item" to="/jvpartners">JV Partners</Link></li> */}

                                            </ul>
                                        </div>
                                    </span>
                                </div>


                            </div>

                        </div>
                    </div>
                    <div className="col-1 mb-2"></div>
                    <div className="col-6 mb-2">
                        <div className="d-flex mt-3 justify-content-end" style={{ display: 'flex' }}>

                            {/* <div className="ms-1 mb-1">
                                <button className="btn w-100 " style={{ backgroundColor: '#16C0EC', fontSize: 20 }} >
                                    <PlusOutlined style={{ fontSize: 24 }}
                                    /> Add JV Partners</button>
                            </div> */}


                            <div className="ms-1 justify-content-end" style={{ display: 'flex' }}>
                                <span className="btn w-100 " style={{ backgroundColor: '#F0F6F5', fontSize: 18 }} >
                                    Series: {totalSerie}</span>
                            </div>


                            <div className="ms-1  justify-content-end" >
                                <span className="btn w-100 " style={{ backgroundColor: '#F6EEEE', fontSize: 18 }} >
                                    Secondary: {TotalSecondary}</span>
                            </div>


                            <div className="ms-1  justify-content-end" style={{ display: 'flex' }}>
                                <span className="btn w-100 " style={{ backgroundColor: '#F0F6F5', fontSize: 18 }} >
                                    Vouchers: {TotalVoucher}</span>
                            </div>

                        </div>

                    </div>
                    <div className="col-4 mb-2"></div>
                </div>

                <div className="row m-0">
                    <div className="col-12 px-0">
                        <Card style={{ opacity: 0.8 }}>
                            <Row >
                                <Col span={8} style={{}}>
                                    <Card bodyStyle={{ maxWidth: "100%", marginBottom:20 }}>
                                        <div style={{ display: 'flex', marginLeft: 2, paddingBottom: 2 }}>
                                            <label style={{
                                                backgroundColor: '#DE0303',
                                                height: 25,
                                                width: 6,
                                                borderRadius: 10
                                            }}></label>
                                            <strong style={{ paddingLeft: 2 }}>List of Series principal</strong>
                                        </div>
                                        <>
                                            <Table
                                                scroll={{ x: "max-content" }}
                                                columns={columnsSerie}

                                                size="small"
                                                dataSource={dataVoucher}
                                                loading={Loading}
                                                pagination={{
                                                    pageSize: 10,
                                                    total: totalPage,
                                                    onChange: (page: any) => {
                                                        LoadSeriePrincipal(page)
                                                    }
                                                }}
                                            />
                                        </>
                                    </Card >
                                </Col>

                                <Col span={8}>
                                    <Card bodyStyle={{ maxWidth: "100%" }}>
                                        <div style={{ display: 'flex', marginLeft: 2, paddingBottom: 2 }}>
                                            <label style={{
                                                backgroundColor: '#DE0303',
                                                height: 25,
                                                width: 6,
                                                borderRadius: 10
                                            }}></label>
                                            <strong style={{ paddingLeft: 2 }}>List of Series secondary by Id : {valueserie}</strong>
                                        </div>
                                        <>
                                            <Table
                                                scroll={{ x: "max-content" }}
                                                columns={columnsSecondary}
                                                dataSource={dataSecondary}
                                                size="small"
                                                // expandable={{
                                                //     rowExpandable: (record) => record.details !== "Not Expandable",
                                                //     expandedRowRender: (record) => (

                                                //         <Card style={{ margin: 0 }}>{record.details}</Card>
                                                //     )
                                                // }}

                                                loading={Loading1}
                                                pagination={{
                                                    pageSize: 10,
                                                    total: totalPage1,
                                                    onChange: (page: any) => {
                                                        LoadSerieSecondary(valueserie, page)
                                                    }
                                                }}
                                            />
                                        </>
                                    </Card>
                                </Col>

                                {/* <Col span={1}></Col> */}

                                <Col span={8}>

                                    <Card bodyStyle={{ maxWidth: "100%" }}>
                                        <div style={{ display: 'flex', marginLeft: 2, paddingBottom: 2 }}>
                                            <label style={{
                                                backgroundColor: '#DE0303',
                                                height: 25,
                                                width: 6,
                                                borderRadius: 10
                                            }}></label>
                                            <strong style={{ paddingLeft: 2 }}>List of Vouchers by Id : {valueVoucher}</strong>
                                        </div>
                                        <>
                                            <Table
                                                scroll={{ x: "max-content" }}
                                                columns={columnsVoucher_Attribute}
                                                dataSource={dataVoucherByIdSecondary}
                                                size="small"
                                                loading={Loading2}

                                            />
                                        </>

                                    </Card>

                                </Col>
                            </Row>

                        </Card>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Vouchers