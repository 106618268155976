import React, { useEffect, useState } from 'react'
import { Card, Select } from "antd"
import toast, { Toaster } from 'react-hot-toast';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import requestUserAccount from '../../controller/requestUserAccount';
import api from '../../config/Config'
const { Option } = Select;

const Profil = () => {
    const data = '';
    const navigate = useNavigate();
    const regexTelephone = new RegExp('^\\+\\d{1,3}\\d{9}$');
    const regexEmail = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');

    const [Loading, setLoading] = useState(false);
    const [province, setPronvince] = useState<requestUserAccount[]>([])
    const [city, setCity] = useState<requestUserAccount[]>([])

    const [minicipality, setMinicipality] = useState<requestUserAccount[]>([])
    const [avatar, setAvatar] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastName] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [email, setEmail] = useState('');
    const [adress, setAddress] = useState('')
    const [minicipal, setMinicipal] = useState('');
    const [loadprov, setLoadProv] = useState('');
    const [loadity, setLoadCity] = useState('');
    const [minicipalName, setMinicipalName] = useState('');


    useEffect(() => {
        if (localStorage.getItem('onktxonktxwwww') == null) {
            navigate('/login')
        }

        loadProvince()
        loadCity()
        loadMinicipality()
        LoadPrile()
        // Promise.all([loadProvince(), loadCity(), loadMinicipality(), LoadPrile()])
        // .catch((error) => {
        //     if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
        //         toast.error("Pas de connexion internet");
        //     } else {
        //         toast.error(error.response.data.message);
        //     }
        // });
    }, []);

    const LoadPrile = async () => {
        api.getHttpRequest("/user/profile/load", data, 'GET').then((response) => {
            const json = response.data.data;
            setName(json.name);
            setMiddlename(json.middlename);
            setLastName(json.lastname);
            setEmail(json.email);
            setPhone(json.phone);
            setAddress(json.address);

            // setMinicipalName(json.minicipalityName);
            setMinicipal(json.MunicipalityId)
            setAvatar(json.avatar);
            setLoadProv(json.province)
            setLoadCity(json.city)
            setMinicipalName(json.municipalityName)
        }).catch((error: any) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    //Update Profile
    const UpdateProfile = async () => {
        const formData = new FormData();
        formData.append("phone", phone);
        formData.append("name", name);
        formData.append("lastname", lastname);
        formData.append("middlename", middlename)
        formData.append("email", email);
        formData.append("address", adress);
        formData.append("MunicipalityId", minicipal);
        formData.append("avatar", avatar);

        var config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: api.var_.url_api_local + '/user/profile/update',
            headers: {
                'Authorization': 'Bearer ' + api.initToken(),
                'Content-Type': 'application/json'
            },
            data: formData
        };
        setLoading(true);
        if (name === '' || lastname === '' || phone === ''
            || email === '' || minicipal === '') {
            toast.error('No fields should be empty');

        } else if (!regexTelephone.test(phone)) {
            toast.error('The entry number is incoreact');

        } else if (!regexEmail.test(email)) {
            toast.error('The email address entered is incoreact');
        }
        else {
            axios(config)
                .then(function (response) {
                    const json = response.data;
                    if (json.status == 200) {
                        toast.success(json.message)
                    } else {
                        toast.error(json.message)
                    }
                })
                .catch((error) => {
                    if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                        toast.error("Please connect your device to the internet");
                    } else if (error.response && error.response.data && error.response.data.message) {
                        toast.error(error.response.data.message);
                    } else {
                        toast.error(error.message);
                    }
                })
        }

        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }

    //Load zones

    const loadProvince = async () => {
        api.getHttpRequest("/zone/province/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setPronvince(json.data);
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const loadCity = async () => {
        api.getHttpRequest("/zone/city/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setCity(json.data);
            }
        }).catch((error) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const loadMinicipality = async () => {
        api.getHttpRequest("/zone/municipality/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setMinicipality(json.data);
            }
        }).catch((error) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }



    const handleImageChange = (e: any) => {
        setAvatar(e.target.files[0]);
    };

    // Fonction qui va permettre des charger le city appartir de la province
    const [stateCity, setStateCity] = useState<requestUserAccount[]>([])
    const [stateMinicipality, setStateMinicipality] = useState<requestUserAccount[]>([])
    const GetProvince = (id: any) => {
        const IdProv = city.filter(x => x.PronvinceId === id);
        setStateCity(IdProv)
        setLoadProv('')
    }

    const GetCity = (id: any) => {
        const IdCity = minicipality.filter(x => x.CityId === id);
        setStateMinicipality(IdCity)
        setLoadCity('')
    }
    const getMinicip = minicipal;
    if (getMinicip !== undefined) {
        const getCti = city.find(city => city.id === getMinicip);

        // const getProv = province.find(province => province.id === getCti.PronvinceId)
        // setPronvince(getProv)
        if (getCti !== undefined) {
            setStateCity(prevState => [...prevState, getCti]);
            setStateCity(prevState => [...prevState, getCti]);
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row" >
                    <div>
                        <Toaster
                            position="bottom-left"
                            reverseOrder={false}
                        />
                    </div>
                    <div className="col-3 mb-2">
                        <div className="pagesHeading d-flex">
                            {/* <div className="pageIcon item-center bgProfile">
                     
                                <i className="fas fa-person-booth fa-lg  align-middle me-3" style={{ color: 'white', width: 40, height: 40, borderRadius: 5 }}></i>
                            </div> */}

                            <div className="my-auto ms-1 d-flex">
                                <div>
                                    <span className="tableHeading d-flex position-relative">
                                        My profile
                                        <div className="dropdown categorydrop">
                                            <button className="btn p-0" type="button" id="categoryMenu" data-bs-toggle="dropdown" aria-expanded="false">
                                                {/* <i className="fa-solid fa-caret-down ms-3 fs-5"></i> */}

                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="categoryMenu">
                                                <h5>Sellers Categories</h5>
                                                {/* <li><Link className="dropdown-item" to="/wallet">Wallet</Link></li>
                                                    <li><Link className="dropdown-item active" to="/investors">Investors</Link></li>
                                                    <li><Link className="dropdown-item" to="/jvpartners">JV Partners</Link></li> */}

                                            </ul>
                                        </div>
                                    </span>
                                </div>


                            </div>

                        </div>
                    </div>
                    <div className="col-1 mb-2"></div>
                    <div className="col-6 mb-2">
                        <div className="d-flex mt-3 justify-content-end" style={{ display: 'flex' }}>

                            {/* <div className="ms-1 mb-1">
                                <button className="btn w-100 " style={{ backgroundColor: '#16C0EC', fontSize: 20 }} >
                                    <PlusOutlined style={{ fontSize: 24 }}
                                    /> Add JV Partners</button>
                            </div> */}




                        </div>

                    </div>
                    <div className="col-1 mb-2"></div>
                </div>
                <div className="row m-0" >

                    <div className="col-12 px-0">
                        <Card style={{ opacity: 0.8 }}>
                            <h4 className="fs-20 text-black pb-2">Update profile

                            </h4>
                            <div className="row  ">
                                <div className="col-3">
                                    <div className="inputBox mb-3">
                                        <input type="text" className="w-100 mt-0" placeholder="Name"
                                            name="name"
                                            value={name}
                                            onChange={(e) => { setName(e.target.value) }}
                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="inputBox mb-3">
                                        <input type="text" className="w-100 mt-0" placeholder="Last name"
                                            name='lastname'
                                            value={lastname}
                                            onChange={(e) => { setLastName(e.target.value) }}

                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="inputBox mb-3">
                                        <input type="text" className="w-100 mt-0" placeholder="Middle name"
                                            name='middlename'
                                            value={middlename}
                                            onChange={(e) => { setMiddlename(e.target.value) }}

                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="inputBox mb-3">
                                        <input type="text" className="w-100 mt-0" placeholder="Phone number"
                                            name='phone'
                                            value={phone}
                                            onChange={(e) => { setPhone(e.target.value) }}

                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="inputBox mb-3">
                                        <input type="text" className="w-100 mt-0" placeholder="Email"
                                            name='email'
                                            value={email}
                                            onChange={(e) => { setEmail(e.target.value) }}
                                        />
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className=" mb-3">
                                        <Select showSearch style={{ width: 308 }} dropdownStyle={{ height: 200 }}

                                            placeholder="Select country" optionFilterProp="children" value={loadprov} onChange={GetProvince}
                                            filterOption={(input: string, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                            <Option selected value="">Select State/Region
                                            </Option>
                                            {
                                                province &&
                                                    province !== undefined ?
                                                    province.map((item: any, i: any) => (
                                                        <Option key={i} value={item.id}>{item.name}</Option>
                                                    ))
                                                    : "No Province"
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className=" mb-3">

                                        <Select showSearch style={{ width: 308, color: 'black' }} dropdownStyle={{ height: 200 }}

                                            placeholder="Select country" optionFilterProp="children" value={loadity} onChange={(e) => GetCity(e)}
                                            filterOption={(input: string, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option selected value="">Select State/City
                                            </Option>
                                            {
                                                stateCity &&
                                                    stateCity !== undefined || stateCity !== null ?
                                                    stateCity.map((item, i) => (
                                                        <Option key={i} value={item.id}>{item.name}</Option>
                                                    ))
                                                    : "No City"
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className=" mb-3">

                                        <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}

                                            placeholder="Select minicipality" optionFilterProp="children" value={minicipalName}
                                            filterOption={(input: string, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                            <Option selected value="">Select/Minicipality
                                            </Option>
                                            {
                                                stateMinicipality &&
                                                    stateMinicipality !== undefined || stateMinicipality !== null ?
                                                    stateMinicipality.map((item, i) => (
                                                        <Option key={i} value={item.id}>{item.name}</Option>
                                                    ))
                                                    : "No minicipality"
                                            }
                                        </Select>

                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="inputBox mb-3">
                                        <textarea className="w-100" rows={1} placeholder="Your address"
                                            name='adress'
                                            value={adress}
                                            onChange={(e) => { setAddress(e.target.value) }}
                                        />
                                    </div>
                                </div>

                                
                                <div className="col-4">
                                    <div className="inputBox mb-3">
                                        {!Loading && (
                                            <button onClick={UpdateProfile} type="button" className="btn w-100 btn-primary  rounded-pill" >Update profile</button>
                                        )}

                                        {Loading && (
                                            <button disabled type="button" className="btn w-100 btn-primary rounded-pill">
                                                <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                            </button>
                                        )}

                                    </div>
                                </div>
                                <div className="col-8"></div>
                            </div>
                        </Card>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Profil