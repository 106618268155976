import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from '../../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { logoSm } from "../../components/icons";
import { Card } from "antd";
import requestDashboard from "../../controller/requestDashboard";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";



const Home = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));

    const data = '';
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);
    const [countVouchers, setCountVouchers] = useState('');
    const [countVouchersUsed, setCountVouchersUsed] = useState('');
    const [countVouchersSeries, setCountVouchersSeries] = useState('');
    const [mobileOperator, setMobileOperator] = useState<requestDashboard[]>([])
    const [vouchersAssigned, setVouchersAssigned] = useState('')
    const [load, setLoad] = useState<requestDashboard[]>([])
    const [listPartnersShip, setListPartnersShip] = useState<requestDashboard[]>([])
    const [countByRoles, setCountByRoles] = useState<requestDashboard[]>([])
    const [countZonesIntervation, setCountZonesIntervation] = useState('')
    const [countCityIntervation, setCountCityIntervation] = useState('')
    const [countMunicipalityIntervation, setCountMunicipalityIntervation] = useState('')

    const [state, setState] = useState(
        {
            series: [{
                name: "Session Duration",
                data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10],
                color: "red",

            },
            {
                name: "Page Views",
                data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35]
            },
            {
                name: 'Total Visits',
                data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56, 45, 47]
            }
            ],
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    color: 'red'
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [5, 7, 5],
                    curve: 'straight',
                    dashArray: [0, 8, 5]
                },
                markers: {
                    size: 0,
                    hover: {
                        sizeOffset: 6
                    }
                },
                xaxis: {
                    categories: ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan', '09 Jan',
                        '10 Jan', '11 Jan', '12 Jan'
                    ],
                },
                tooltip: {
                    y: [
                        {
                            title: {
                                formatter: function (val: any) {
                                    return val + " (mins)"
                                }
                            }
                        },
                        {
                            title: {
                                formatter: function (val: any) {
                                    return val + " per session"
                                }
                            }
                        },
                        {
                            title: {
                                formatter: function (val: any) {
                                    return val;
                                }
                            }
                        }
                    ]
                },
                grid: {
                    borderColor: 'white',
                }
            }


        }
    );

    const [chart2, setChart2] = useState({
        series: [
            {
                data: [
                    {
                        x: '2008',
                        y: [2800, 4500]
                    },
                    {
                        x: '2009',
                        y: [3200, 4100]
                    },
                    {
                        x: '2010',
                        y: [2950, 7800]
                    },
                    {
                        x: '2011',
                        y: [3000, 4600]
                    },
                    {
                        x: '2012',
                        y: [3500, 4100]
                    },
                    {
                        x: '2013',
                        y: [4500, 6500]
                    },
                    {
                        x: '2014',
                        y: [4100, 5600]
                    }
                ],

            }
        ],
        options: {
            chart: {
                height: 350,
                type: 'rangeBar',
                zoom: {
                    enabled: false
                }
            },
            plotOptions: {
                bar: {
                    isDumbbell: true,
                    columnWidth: 3,
                    dumbbellColors: [['#1351F5', '#1351F5']]
                }
            },
            legend: {
                show: true,
                showForSingleSeries: true,
                position: 'top',
                horizontalAlign: 'left',
                customLegendItems: ['Product A', 'Product B']
            },
            fill: {
                type: 'gradient',
                gradient: {
                    type: 'vertical',
                    gradientToColors: ['#68C4EE'],
                    inverseColors: true,
                    stops: [0, 100]
                }
            },
            grid: {
                xaxis: {
                    lines: {
                        show: true
                    }
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                }
            },
            xaxis: {
                tickPlacement: 'on'
            }
        }
    })



    useEffect(() => {
        if (localStorage.getItem('onktxonktxwwww') == null) {
            navigate('/login')
        }
        LoadDashboard()
    }, []);



    const LoadDashboard = async () => {

        api.getHttpRequest(`/dashboard/managment`, data, 'GET').then((response) => {
            const json = response.data;
            setCountVouchers(json.data.count_vouchers)
            setCountVouchersUsed(json.data.count_vouchers_used)
            setCountVouchersSeries(json.data.count_vouchers_series)
            setMobileOperator(json.data.mobile_operator)
            setVouchersAssigned(json.data.vouchers_assigned)
            setLoad(json.data.list_count_vouchers_by_partner)
            setListPartnersShip(json.data.list_partners_ship)
            setCountByRoles(json.data.count_by_roles)
            setCountZonesIntervation(json.data.count_zones_intervation)
            setCountCityIntervation(json.data.count_city_intervation)
            setCountMunicipalityIntervation(json.data.count_Municipality_intervation)

        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row rowhome">
                    {
                        isMatch ? (
                            <>
                                <div className="col-12">
                                    <div className="row m-0 mb-2">
                                        <div className="position-relative  rounded-5 d-flex" style={{ backgroundColor: '#1351F5' }}>

                                            <div className="col-4 linechartHeading">
                                                <span className="fw-bold" style={{ color: 'white' }}>Total series</span>
                                                <span className=" d-block" style={{ color: 'white' }}>{countVouchersSeries}</span>
                                            </div>

                                            <div className="col-4 linechartHeading">
                                                <span className="fw-bold" style={{ color: 'white' }}>Vouchers</span>
                                                <span className=" d-block" style={{ color: 'white' }}>{countVouchers}</span>
                                            </div>

                                            <div className="col-4 linechartHeading">
                                                <span className="fw-bold" style={{ color: 'white' }}>Total used</span>
                                                <span className=" d-block" style={{ color: 'white' }}>{countVouchersUsed}</span>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row m-0 mb-3">
                                        <div className="position-relative bg-white px-1 py-2 rounded-5">
                                            <div className="">
                                                <div className="row  mb-2">
                                                    <div className="col-8 linechartHeading">
                                                        <div style={{ display: 'flex', marginLeft: 2, paddingBottom: 1 }}>
                                                            <label style={{
                                                                backgroundColor: '#1351F5',
                                                                height: 25,
                                                                width: 6,
                                                                borderRadius: 10
                                                            }}></label>
                                                            <strong style={{ paddingLeft: 2 }}>Total of vouchers assigned : {vouchersAssigned}</strong>

                                                        </div>
                                                    </div>
                                                    <div className="col-4 d-flex my-auto justify-content-end">

                                                    </div>
                                                </div>
                                                {/* <canvas id="lineChart" width="400" height="90"></canvas> */}

                                                <div className="row">
                                                    {
                                                        load.map((item, i) => (
                                                            <div key={i} className="col-12 mb-1">
                                                                <div className="chartlinks  navbar-expand d-flex">
                                                                    <div className="row col-12">
                                                                        <div className="col-10">
                                                                            <ul className="navbar-nav">
                                                                                <li className="nav-item " ><span style={{ fontSize: 12, color: 'black' }}>JV Name:</span> <br />
                                                                                    {item.partner_name}</li>

                                                                            </ul>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <h5 className="nomberVoucher">{item.nombre}</h5>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        ))
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row m-0 mb-3">
                                        <div className="position-relative bg-white px-5 py-4 rounded-5">
                                            <div className="topBar">
                                                <div className="row m-0 mb-4">
                                                    <div className="col-4 linechartHeading">
                                                        <span className="fw-bold">Transaction Overview</span>
                                                        <span className="gray-text d-block">$1,959.34</span>
                                                    </div>
                                                    <div className="col-8 d-flex my-auto justify-content-end">
                                                        <div className="chartDates my-auto">
                                                            <span>14.12.2019</span><span className="bg-white">-</span><span>23.01.2020</span>
                                                        </div>
                                                        <div>
                                                            <div className="chartlinks ms-4 navbar-expand">
                                                                <ul className="navbar-nav">
                                                                    <li className="nav-item fw-bold active">24H</li>
                                                                    <li className="nav-item fw-bold">1W</li>
                                                                    <li className="nav-item fw-bold">1M</li>
                                                                    <li className="nav-item fw-bold">1Y</li>
                                                                    <li className="nav-item fw-bold">5Y</li>
                                                                    <li className="nav-item fw-bold">ALL</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <canvas id="lineChart" width="400" height="90"></canvas> */}

                                                <ReactApexChart
                                                    options={chart2.options as any}
                                                    series={chart2.series}
                                                    type="line"
                                                    height={250}
                                                    width="100%"
                                                />

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row m-0 mb-2">
                                        {
                                            mobileOperator.map((item, i) => (
                                                <Card key={i}
                                                    hoverable={true}
                                                    size="small"
                                                    style={{ width: '200px', backgroundColor: 'transparent' }}
                                                    cover={<img alt="images" src={item.icon} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />}

                                                    bordered={false}
                                                >
                                                    {item.name}
                                                </Card>
                                            ))
                                        }

                                    </div>

                                </div>
                                <div className="col-12">

                                    <div className="revenueCard mb-3 bg-white text-center">
                                        <div className="headingBar fw-bold py-4"><span className="fw-bold">List partners ship</span></div>
                                        <div className="p-3">
                                            {
                                                listPartnersShip.map((item, i) => (
                                                    <div className="revenueDetails mb-3 d-flex">
                                                        <div className="fw-bold">{item.name}</div>
                                                        <div className="mx-auto fw-bold">-</div>
                                                        <div className="show-details">{item.nombre}</div>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    </div>

                                    <div className="revenueCard mb-3 bg-white text-center">
                                        <div className="headingBar fw-bold py-4"><span className="fw-bold">Count by roles</span></div>
                                        <div className="p-3">
                                            {
                                                countByRoles.map((item, i) => (
                                                    <div key={i} className="revenueDetails mb-3 d-flex">
                                                        <div className="fw-bold">{item.name}</div>
                                                        <div className="mx-auto fw-bold">-</div>
                                                        <div className="show-details">{item.nombre}</div>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    </div>

                                    <div className="revenueCard mb-3 bg-white text-center">
                                        <div className="headingBar fw-bold py-4"><span className="fw-bold">Zones intervation</span></div>
                                        <div className="p-3">
                                            <div className="revenueDetails mb-3 d-flex">
                                                <div className="fw-bold">Region</div>
                                                <div className="mx-auto fw-bold">-</div>
                                                <div className="show-details">{countZonesIntervation}</div>
                                            </div>
                                            <div className="revenueDetails mb-3 d-flex">
                                                <div className="fw-bold">District</div>
                                                <div className="mx-auto fw-bold">-</div>
                                                <div className="show-details">{countCityIntervation}</div>
                                            </div>
                                            <div className="revenueDetails d-flex">
                                                <div className="fw-bold">Minicipality</div>
                                                <div className="mx-auto fw-bold">-</div>
                                                <div className="show-details">{countMunicipalityIntervation}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        ) : (
                            <>
                                <div className="col-3">

                                    <div className="revenueCard mb-3 bg-white text-center">
                                        <div className="headingBar fw-bold py-4"><span className="fw-bold">List partners ship</span></div>
                                        <div className="p-3">
                                            {
                                                listPartnersShip.map((item, i) => (
                                                    <div className="revenueDetails mb-3 d-flex">
                                                        <div className="fw-bold">{item.name}</div>
                                                        <div className="mx-auto fw-bold">-</div>
                                                        <div className="show-details">{item.nombre}</div>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    </div>

                                    <div className="revenueCard mb-3 bg-white text-center">
                                        <div className="headingBar fw-bold py-4"><span className="fw-bold">Count by roles</span></div>
                                        <div className="p-3">
                                            {
                                                countByRoles.map((item, i) => (
                                                    <div key={i} className="revenueDetails mb-3 d-flex">
                                                        <div className="fw-bold">{item.name}</div>
                                                        <div className="mx-auto fw-bold">-</div>
                                                        <div className="show-details">{item.nombre}</div>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    </div>

                                    <div className="revenueCard mb-3 bg-white text-center">
                                        <div className="headingBar fw-bold py-4"><span className="fw-bold">Zones intervation</span></div>
                                        <div className="p-3">
                                            <div className="revenueDetails mb-3 d-flex">
                                                <div className="fw-bold">Region</div>
                                                <div className="mx-auto fw-bold">-</div>
                                                <div className="show-details">{countZonesIntervation}</div>
                                            </div>
                                            <div className="revenueDetails mb-3 d-flex">
                                                <div className="fw-bold">District</div>
                                                <div className="mx-auto fw-bold">-</div>
                                                <div className="show-details">{countCityIntervation}</div>
                                            </div>
                                            <div className="revenueDetails d-flex">
                                                <div className="fw-bold">Minicipality</div>
                                                <div className="mx-auto fw-bold">-</div>
                                                <div className="show-details">{countMunicipalityIntervation}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="row m-0 mb-2">
                                        <div className="position-relative px-5 py-4 rounded-5 d-flex" style={{ backgroundColor: '#1351F5' }}>

                                            <div className="col-4 linechartHeading">
                                                <span className="fw-bold" style={{ color: 'white' }}>Total series vouchers</span>
                                                <span className=" d-block" style={{ color: 'white' }}>{countVouchersSeries}</span>
                                            </div>

                                            <div className="col-4 linechartHeading">
                                                <span className="fw-bold" style={{ color: 'white' }}>Total vouchers</span>
                                                <span className=" d-block" style={{ color: 'white' }}>{countVouchers}</span>
                                            </div>

                                            <div className="col-4 linechartHeading">
                                                <span className="fw-bold" style={{ color: 'white' }}>Total vouchers used</span>
                                                <span className=" d-block" style={{ color: 'white' }}>{countVouchersUsed}</span>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row m-0 mb-3">
                                        <div className="position-relative bg-white px-1 py-2 rounded-5">
                                            <div className="">
                                                <div className="row  mb-2">
                                                    <div className="col-8 linechartHeading">
                                                        <div style={{ display: 'flex', marginLeft: 2, paddingBottom: 1 }}>
                                                            <label style={{
                                                                backgroundColor: '#1351F5',
                                                                height: 25,
                                                                width: 6,
                                                                borderRadius: 10
                                                            }}></label>
                                                            <strong style={{ paddingLeft: 2 }}>Total of vouchers assigned : {vouchersAssigned}</strong>

                                                        </div>
                                                    </div>
                                                    <div className="col-4 d-flex my-auto justify-content-end">

                                                    </div>
                                                </div>
                                                {/* <canvas id="lineChart" width="400" height="90"></canvas> */}

                                                <div className="row">
                                                    {
                                                        load.map((item, i) => (
                                                            <div key={i} className="col-4 mb-1">
                                                                <div className="chartlinks  navbar-expand d-flex">
                                                                    <div className="row col-12">
                                                                        <div className="col-10">
                                                                            <ul className="navbar-nav">
                                                                                <li className="nav-item " ><span style={{ fontSize: 12, color: 'black' }}>JV Name:</span> <br />
                                                                                    {item.partner_name}</li>

                                                                            </ul>
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <h5 className="nomberVoucher">{item.nombre}</h5>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        ))
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row m-0 mb-3">
                                        <div className="position-relative bg-white px-5 py-4 rounded-5">
                                            <div className="topBar">
                                                <div className="row m-0 mb-4">
                                                    <div className="col-4 linechartHeading">
                                                        <span className="fw-bold">Transaction Overview</span>
                                                        <span className="gray-text d-block">$1,959.34</span>
                                                    </div>
                                                    <div className="col-8 d-flex my-auto justify-content-end">
                                                        <div className="chartDates my-auto">
                                                            <span>14.12.2019</span><span className="bg-white">-</span><span>23.01.2020</span>
                                                        </div>
                                                        <div>
                                                            <div className="chartlinks ms-4 navbar-expand">
                                                                <ul className="navbar-nav">
                                                                    <li className="nav-item fw-bold active">24H</li>
                                                                    <li className="nav-item fw-bold">1W</li>
                                                                    <li className="nav-item fw-bold">1M</li>
                                                                    <li className="nav-item fw-bold">1Y</li>
                                                                    <li className="nav-item fw-bold">5Y</li>
                                                                    <li className="nav-item fw-bold">ALL</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <canvas id="lineChart" width="400" height="90"></canvas> */}

                                                <ReactApexChart
                                                    options={chart2.options as any}
                                                    series={chart2.series}
                                                    type="line"
                                                    height={250}
                                                    width="100%"
                                                />

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row m-0 mb-2">
                                        {
                                            mobileOperator.map((item, i) => (
                                                <Card key={i}
                                                    hoverable={true}
                                                    size="small"
                                                    style={{ width: '200px', backgroundColor: 'transparent' }}
                                                    cover={<img alt="images" src={item.icon} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />}

                                                    bordered={false}
                                                >
                                                    {item.name}
                                                </Card>
                                            ))
                                        }

                                    </div>


                                    {/* <div className="row m-0 mb-5">
    <div className="col-8 ps-0">
        <div className="bg-white p-5 py-4 position-relative rounded-5">
            <div className="row m-0 mb-4 topBar">
                <div className="col-6 p-0">
                    <span className="barChart fw-bold">2022 Per Month  Tiva Spendings</span>
                </div>
                <div className="col-6 p-0">
                    <div className="barChartTag justify-content-end d-flex">
                        <div className="d-flex my-auto fw-bold"><div className="red me-3 my-auto"></div>Vendors, Agents and Investors</div>
                        <div className="d-flex ms-4 my-auto fw-bold"><div className="blue me-3 my-auto"></div>Computers Program</div>
                    </div>
                </div>
            </div>
            <ReactApexChart options={chart2.options as ApexOptions} series={chart2.series} type="rangeBar" height={300} />
        </div>
    </div>
    <div className="col-4 pe-0">
        <div className="bg-white py-4 px-5 position-relative rounded-5 topBar h-100">
            <div className="chartHeading">
                <span className="d-block fw-bold small-font">Tiva Spendings</span>
                <span >September, 2022</span>
            </div>
            <div className="pieChart mt-5">
                <div className="barChartTag">
                    <div className="d-flex mb-2 my-auto fw-bold"><div className="red me-3 my-auto"></div>Hospital Claims</div>
                    <div className="d-flex my-auto fw-bold"><div className="blue me-3 my-auto"></div>Salaries</div>
                </div>
                <canvas id="pieChart" width="400" height="200"></canvas>
            </div>
        </div>
    </div>
</div> */}
                                </div>
                            </>
                        )
                    }

                </div>
            </div>

        </>
    )
}

export default Home

