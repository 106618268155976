import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { formatDistanceStrict } from 'date-fns';

const var_ = {

    url_api_local: 'http://tivaintl.com:4032/api/v1',
    url_avatar: 'http://api-national-tz.tivaintl.com/uploads/',
    // url_api_local: 'http://192.168.163.2:4032/api/v1',
    // url_avatar: 'http://api-national-tz.tivaintl.com/uploads/',
    token: null,

}

const key2 = 'ma_clé_de_chiffrement_user_connecter';
const key3 = "cle_avatar";
// Récupérer la variable chiffrée depuis le local storage
let user_Cripter: string | any = localStorage.getItem('sutielzatiwouuuu');
let avatar_Cripter: string | any = localStorage.getItem('obvokotorateboavavav');
// Décrypter la variable
let user_Decripter: any;
// const initUser = () => {
//     user_Cripter = localStorage.getItem('sutielzatiwouuuu');
//     const bytes = CryptoJS.AES.decrypt(user_Cripter, key2);
//     user_Decripter = bytes.toString(CryptoJS.enc.Utf8);

//     return user_Decripter.slice(0, 7);  
// }

const initUser = () => {
    user_Cripter = localStorage.getItem('sutielzatiwouuuu');
    if (user_Cripter !== null) {
      const bytes = CryptoJS.AES.decrypt(user_Cripter, key2);
      user_Decripter = bytes.toString(CryptoJS.enc.Utf8);
      return user_Decripter.slice(0, 7);  
    }
    return '';
  }
  

let avatar_Decripter:any;
const initAvatar = () => {
    avatar_Cripter = localStorage.getItem('obvokotorateboavavav');
    if (avatar_Cripter !==  null) {
        const bytes = CryptoJS.AES.decrypt(avatar_Cripter, key3);
        avatar_Decripter = bytes.toString(CryptoJS.enc.Utf8);
    
        return avatar_Decripter;
    }
    return '';
}


// Définir la clé de chiffrement
const key = 'ma_clé_de_chiffrement_token_user';

// Récupérer la variable chiffrée depuis le local storage
let token_Cripter: string | any = localStorage.getItem('onktxonktxwwww');

// Décrypter la variable\
let token_Decripter: any;
const initToken = () => {
    token_Cripter = localStorage.getItem('onktxonktxwwww');
    const bytes = CryptoJS.AES.decrypt(token_Cripter, key);
    token_Decripter = bytes.toString(CryptoJS.enc.Utf8);

    return token_Decripter;
}

const USER: string[] = [];

const getHttpRequest = async (route: any, data: any, method = 'POST', content_type = 'application/json') => {
    initToken();
    const url = var_.url_api_local + route;

    let config_api = {
        method: method,
        url: url,
        headers: {
            'Content-Type': content_type,
            'Authorization': (localStorage.getItem("onktxonktxwwww") == null || localStorage.getItem("onktxonktxwwww") == undefined) ? '' : `Bearer ${token_Decripter}`,
            'accept': 'application/json'
        },
        data: data
    };

    return await axios.request(config_api);


};

const getHttpRequestNotToken = async (route: any, data: any, method = 'POST', content_type = 'application/json') => {

    const url = var_.url_api_local + route;
    // console.log("Request : " + url)
    let config_api = {
        method: method,
        url: url,
        headers: {
            'Content-Type': content_type,
            'Authorization': null,
            'accept': 'application/json'
        },
        data: data
    };

    return await axios.request(config_api);


};

const checkStatus = (status: any, navigation = null) => {

    switch (status) {
        case 403:
            var_.token = null;
            < Navigate to="Login" replace />
            break;
        case "Request failed with status code 403":
            var_.token = null;
            < Navigate to="Login" replace />
            break;
        case 401: var_.token = null;
            < Navigate to="Login" replace />
            break;
        default:
            break;
    }
}

// function geDateFormat(dateString: string): string {
//     const date = new Date(Date.parse(dateString));
//     const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
//     const formattedDate = date.toLocaleDateString('en-US', options);
//     return formattedDate;
// }

// function geDateFormat(dateString: string): string {
//     const date = new Date(Date.parse(dateString));
//     const now = new Date();
//     const diff = now.getTime() - date.getTime();
//     const relativeDate = formatDistanceStrict(date, now, { addSuffix: true });
//     return relativeDate;
// }

function geDateFormat(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return 'Invalid date';
    }
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const relativeDate = formatDistanceStrict(date, now, { addSuffix: true });
    return relativeDate;
}



export default { var_, getHttpRequest, getHttpRequestNotToken, token_Decripter, user_Decripter, initToken, initUser, initAvatar, geDateFormat};