import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import api from '../../../config/Config'
import toast, { Toaster } from 'react-hot-toast';
import CryptoJS from 'crypto-js';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { logoSm } from '../../../components/icons';
import "../../../components/assets/css1/css/app.css";
import "../../../components/assets/css1/css/bootstrap-grid.min.css";
import "../../../components/assets/css1/icons/boxicons-2/css/boxicons.min.css";



const Login = () => {

    const navigate = useNavigate()
    const [Loading, setLoading] = useState(false);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const changePhoneNumber = (value: string, country: any) => {
        const countryCode = country.dialCode;
        const phoneNumber = value.replace(countryCode, `+${countryCode}`);
        setUsername(phoneNumber);
    };

    useEffect(() => {

        const storedUsername = localStorage.getItem("phone_account");
        if (storedUsername === null || storedUsername === "") {
            setUsername("");
        } else {
            setUsername(storedUsername);
        }
    }, []);


    const loginSubmit = async () => {
        let data = JSON.stringify({
            "username": username,
            "password": password
        });

        setLoading(true);

        if (username === '' || password === '') {
            toast.error('The Email and Password fields must not be empty');

        } else {
            api.getHttpRequestNotToken("/user/login", data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    setUsername('');
                    setPassword('');
                    // Définir la clé de chiffrement
                    if (json.data !== null) {
                        const key = 'ma_clé_de_chiffrement_token_user';
                        const key_user = 'ma_clé_de_chiffrement_user_connecter'
                        const key_avatar = "cle_avatar"
                        // Définir la variable à crypter
                        const token = json.data.token;
                        const user_connect = json.data.username;
                        const user_avatar = json.data.avatar;
                        const tokenCrypter = CryptoJS.AES.encrypt(token, key).toString();
                        const userconnectCrypter = CryptoJS.AES.encrypt(user_connect, key_user).toString();
                        const avatarCrypter = CryptoJS.AES.encrypt(user_avatar, key_avatar).toString();

                        localStorage.setItem('onktxonktxwwww', tokenCrypter);
                        localStorage.setItem('sutielzatiwouuuu', userconnectCrypter);
                        localStorage.setItem('obvokotorateboavavav', avatarCrypter);
                        navigate('/home')
                    }



                } else {
                    toast.error(json.message);
                }
            }).catch((error) => {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            })

        }

        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }
    return (
        <>
            <div className="auth-multi-layout">
                <div className="auth-box">
                    <div className="auth-header">
                        <div className="auth-header-logo">
                            <img src={logoSm} alt="" className="auth-header-logo-img" />
                        </div>
                        <p className="auth-header-subtitle">
                            Login to your account and start work
                        </p>
                    </div>
                    <div className="auth-body">
                        <div className="auth-form-validation">
                            <div>
                                <Toaster
                                    position="bottom-left"
                                    reverseOrder={false}
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="email" className="input-label">
                                    Username or email
                                </label>

                                <input type="text" className="form-control" placeholder="Email or phone number"
                                    autoComplete="off"
                                    required name="username"
                                    onChange={(e) => setUsername(e.target.value)}
                                    value={username}
                                />
                                {/* <PhoneInput
                                    inputStyle={{
                                        display: 'block',
                                        width: '100%',
                                        height: '55px',
                                        padding: '6px 12px',
                                        fontSize: '14px',
                                        borderRadius: '5px',
                                        border: '1px solid #8e8e97',
                                        outline: 'none',
                                        transition: 'border 0.1s ease-in, box-shadow 0.15s ease-in'
                                    }}
                                    inputClass={'input-field'}
                                    country={'tz'}
                                    value={username}
                                    onChange={changePhoneNumber}
                                    enableSearch={true}
                                    countryCodeEditable={true}
                                /> */}
                            </div>
                            <div className="input-field">
                                <label htmlFor="password" className="input-label">
                                    Password
                                </label>
                                <input type="password" name="password" className="form-control" placeholder="Password"
                                    autoComplete="off"
                                    required
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                />
                            </div>
                            <div className="input-field">
                                <div className="checkbox">
                                    <label>
                                        <input id="login-remember" type="checkbox" name="remember" /> Remember me
                                    </label>
                                </div>
                            </div>

                            <div className="flex-end">
                                <Link to={"/forgot_pswd"} className="link-end">
                                    Forgot password?
                                </Link>
                            </div>
                            {/* <button type="submit" className="btn-submit" onClick={loginSubmit}>
                    Sign in
                  </button> */}

                            {!Loading && (
                                <button type="submit" className="btn-submit" onClick={loginSubmit}>
                                    Sign in
                                </button>
                            )}

                            {Loading && (

                                <button type="submit" className="btn-submit" disabled>
                                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                </button>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Login
