import { Modal, Popconfirm, Select } from "antd"
import { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import api from '../../../../config/Config'
import { useNavigate } from "react-router-dom";
import RowParams from "../../../../controller/requestRowParams";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";
const { Option } = Select;

const City = () => {
    const data = '';
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('')
    const [id, setId] = useState('')
    const [idProvince, setIdProvince] = useState('')

    //Row params
    const [load, setLoad] = useState<RowParams[]>([])
    const [loadProvince, setLoadProvince] = useState<RowParams[]>([])

    useEffect(() => {
        if (localStorage.getItem('onktxonktxwwww') == null) {
            navigate('/login')
        }

        LoadCity()
        LoadProvince()
    }, []);

    const LoadCity = async () => {
        api.getHttpRequest("/zone/city/load", data, 'GET').then((response) => {
            const json = response.data;
            setLoad(json.data)

        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }


    const LoadProvince = async () => {
        api.getHttpRequest("/zone/province/load", data, 'GET').then((response) => {
            const json = response.data;
            setLoadProvince(json.data)

        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const addCity = async () => {
        let data = JSON.stringify({
            "name": name,
            "description": description,
            "id_province": idProvince
        });

        setLoading(true);

        if (name === '' || description === '') {
            toast.error('The name and description fields must not be empty');

        } else {
            api.getHttpRequest("/zone/city/create", data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 201) {
                    setName('');
                    setDescription('');
                    setOpen(false);
                    toast.success(json.message);
                    LoadCity()
                } else {
                    toast.error(json.message);
                }
            }).catch((error) => {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            })

        }

        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }

    const UpdateCity = async (id: any) => {
        let data = JSON.stringify({
            "name": name,
            "description": description,
            "id_province": idProvince
        });

        setLoading(true);

        if (name === '' || description === '') {
            toast.error('The name and description fields must not be empty');

        } else {
            api.getHttpRequest(`/zone/city/update/${id}`, data, 'PUT').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    setName('');
                    setDescription('');
                    setOpen2(false);
                    toast.success(json.message);
                    LoadCity()
                } else {
                    toast.error(json.message);
                }
            }).catch((error) => {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            })

        }

        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }

    const DeleteProvince = (value: any) => {
        api.getHttpRequest(`/zone/city/delete/${value}`, data, 'delete').then((response) => {
            if (response.data.status == 201) {
                toast.success(response.data.message);
                setLoading(false);
                LoadCity()
            }
            else {
                setLoading(false);
                toast.error(response.data.message);
            }
        }).catch((error) => {
            setLoading(false);
            toast.error(error.message);
        })

    }


    //Modal Request
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [confirmLoading2, setConfirmLoading2] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    function showModalUpdate(id_: any, name_: any, description_: any) {
        setOpen2(true);
        setId(id_)
        setName(name_)
        setDescription(description_)
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        setOpen(false);
    };
    const handleOk2 = () => {
        setConfirmLoading2(true);
        setTimeout(() => {
            setOpen2(false);
            setConfirmLoading2(false);
        }, 2000);
    };

    const handleCancel2 = () => {
        setOpen2(false);
    };
    return (
        <>
            <div className="row m-0  bg-white profilePage">
                <div className="row" >
                    <div>
                        <Toaster
                            position="bottom-left"
                            reverseOrder={false}
                        />
                    </div>
                    <div className="col-10">
                        {/* <h5 className="fs-5 mb-2">New province:</h5> */}
                        <div style={{ display: 'flex', marginLeft: 2, paddingBottom: 2 }}>
                            <label style={{
                                backgroundColor: '#DE0303',
                                height: 25,
                                width: 6,
                                borderRadius: 10
                            }}></label>
                            <strong style={{ paddingLeft: 2 }}>List of District</strong>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="d-flex mb-2 justify-content-end" style={{ display: 'flex' }}>
                            <button className="btn w-100 " style={{ backgroundColor: '#F6EEEE', fontSize: 16 }} onClick={showModal}>
                                <PlusOutlined style={{ fontSize: 16, marginTop: 0 }} />District</button>
                        </div>

                    </div>
                </div>
                <div className="row m-0">
                    <div className="col-12 px-0">
                        <table className="table secondTable resizable">
                            <thead>
                                <tr>
                                    <th style={{ width: 10, paddingLeft: 15 }}>#</th>
                                    <th >Name</th>
                                    <th >Description</th>
                                    <th >createdAt</th>
                                    <th >updatedAt</th>
                                    <th >Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    load.map((item, i) => (
                                        <tr key={i}>
                                            <th style={{ width: 10, paddingLeft: 15 }}>{i + 1}</th>
                                            <td>{item.name}</td>
                                            <td>{item.description}</td>
                                            <td>{api.geDateFormat(item.createdAt)}</td>
                                            <td>{api.geDateFormat(item.updatedAt)}</td>

                                            <td>
                                                <div className="d-flex">
                                                    <button className="btn shadow-btn" type="button"
                                                        onClick={() => showModalUpdate(`${item.id}`, `${item.name}`, `${item.description}`)}
                                                    ><span>Edit</span><i className="fa-regular fa-pen-to-square"></i></button>
                                                    <Popconfirm title={`Are you sure want to delete ${item.name} ?`} onConfirm={() => DeleteProvince(item.id)}>
                                                        <button className="btn shadow-btn ms-3"><span>Delete</span><i className="fa-solid fa-trash-can"></i></button>
                                                    </Popconfirm>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }


                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <Modal
                title="New District"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={false}
                width={1000}
            >
                <>
                    <div className="row  ">
                        <div className="col-12">
                            {/* <div className="inputBox mb-3">
                                <label htmlFor="">District/Name</label>
                                <select className="form-select" value={idProvince} onChange={(e) => setIdProvince(e.target.value)}>
                                    <option>Select province</option>
                                    {
                                        loadProvince.map((item, i) => (
                                            <option key={i} value={item.id}>{item.name}</option>
                                        ))
                                    }
                                </select>  
                            </div> */}

                            <label className="form-label">Region</label>

                            <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}

                                placeholder="Select country" optionFilterProp="children" value={idProvince} onChange={(e) => setIdProvince(e)}
                                filterOption={(input: string, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                <Option selected value="">Select/Region
                                </Option>
                                {
                                    loadProvince &&
                                        loadProvince !== undefined ?
                                        loadProvince.map((item, i) => (
                                            <Option key={i} value={item.id}>{item.name}</Option>
                                        ))
                                        : "No Province"
                                }
                            </Select>
                        </div>

                        <div className="col-12">
                            <div className="inputBox mb-3">
                                <label htmlFor="">District/Name</label>
                                <input type="text" className="w-100" placeholder=""
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="inputBox mb-3">
                                <label htmlFor="">Description</label>
                                <textarea className="w-100" placeholder="" style={{ color: 'black' }}
                                    name="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>  <hr style={{ marginBottom: 5 }} />
                        <div className="col-12">
                            <div className="inputBox mb-3">
                                {!Loading && (
                                    <button onClick={addCity} type="button" className="btn w-100 btn-primary rounded-pill">Submit</button>
                                )}

                                {Loading && (
                                    <button disabled type="button" className="btn w-100 btn-primary rounded-pill">
                                        <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                    </button>
                                )}

                            </div>
                        </div>
                    </div>
                </>
            </Modal>

            <Modal
                title="Upddate City"
                open={open2}
                onOk={handleOk2}
                confirmLoading={confirmLoading2}
                onCancel={handleCancel2}
                footer={false}
                width={1000}
            >
                <>
                    <div className="row  ">

                        <div className="col-12">
                            {/* <div className="inputBox mb-3">
                                <label htmlFor="">Province/Name</label>
                                <select className="form-select" value={idProvince} onChange={(e) => setIdProvince(e.target.value)}>
                                    <option>Select province</option>
                                    {
                                        loadProvince.map((item, i) => (
                                            <option key={i} value={item.id}>{item.name}</option>
                                        ))
                                    }
                                </select>
                            </div> */}

                            <label className="form-label">Region</label>

                            <Select showSearch style={{ width: "100%" }} dropdownStyle={{ height: 200 }}

                                placeholder="Select country" optionFilterProp="children" value={idProvince} onChange={(e) => setIdProvince(e)}
                                filterOption={(input: string, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                <Option selected value="">Select/Region
                                </Option>
                                {
                                    loadProvince &&
                                        loadProvince !== undefined ?
                                        loadProvince.map((item, i) => (
                                            <Option key={i} value={item.id}>{item.name}</Option>
                                        ))
                                        : "No Province"
                                }
                            </Select>
                        </div>

                        <div className="col-12">
                            <input style={{ display: 'none' }} value={id} onChange={(e) => setId(e.target.value)} type="text" />
                            <div className="inputBox mb-3">
                                <label htmlFor="">City/Name</label>
                                <input type="text" className="w-100" placeholder=""
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    style={{ color: 'black' }}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="inputBox mb-3">
                                <label htmlFor="">Desciption</label>
                                <textarea className="w-100" placeholder="" style={{ color: 'black' }}
                                    name="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div><hr style={{ marginBottom: 5 }} />
                        <div className="col-12">
                            <div className="inputBox mb-3">
                                {!Loading && (
                                    <button onClick={() => UpdateCity(id)} type="button" className="btn w-100 btn-primary rounded-pill">Update</button>
                                )}

                                {Loading && (
                                    <button disabled type="button" className="btn w-100 btn-primary rounded-pill">
                                        <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                    </button>
                                )}

                            </div>
                        </div>
                    </div>
                </>
            </Modal>
        </>
    )
}

export default City