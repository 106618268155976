import { forgot } from "../../../components/icons"
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../../config/Config';
import toast, { Toaster } from 'react-hot-toast';
import CryptoJS from 'crypto-js';
import "../../../components/assets/css1/css/app.css";
import "../../../components/assets/css1/css/bootstrap-grid.min.css";
import "../../../components/assets/css1/icons/boxicons-2/css/boxicons.min.css";

const ForgotPass = () => {

    const navigate = useNavigate()
    const [Loading, setLoading] = useState(false);

    const [account, setAcount] = useState({
        email_or_phone: '',

    });
    const onChangeValue = (e: any) => {
        setAcount({
            ...account,
            [e.target.name]: e.target.value
        })
    }

    const FourgotSubmit = async () => {

        let data = JSON.stringify({
            "email_or_phone": account.email_or_phone
        });

        setLoading(true);

        if (account.email_or_phone === '') {
            toast.error('The email_or_phone field must not be empty')
        }
        else {
            api.getHttpRequestNotToken("/user/forgot-password/start", data, 'POST').then((response) => {
                const json = response.data;
                if (json.status == 200) {
                    toast.success(json.message)
                    const key = 'phone_account';
                    // Définir la variable à crypter
                    const phone_account = account.email_or_phone;
                    const phoneCrypter = CryptoJS.AES.encrypt(phone_account, key).toString();
                    localStorage.setItem('zxremonailnephoypnmal', phoneCrypter)
                    navigate('/verify_forgot')
                } else {
                    toast.error(json.message)
                }
            }).catch((error) => {
                if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                    toast.error("Please connect your device to the internet");
                } else if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.message);
                }
            })
        }

        setTimeout(() => {
            setLoading(false);
        }, 5000)
    }

    return (
        <>
            <div className="auth-multi-layout">
                <div className="auth-box">
                    <div className="auth-header">
                        <div className="auth-header-logo forgot-img">
                            <img src={forgot} alt="" className="auth-header-logo-img" />
                        </div>
                    </div>
                    <div className="auth-body">
                        <h1 className="auth-header-title">Forgot Password?</h1>
                        <p className="auth-header-subtitle forgot-subtitle">
                            Enter your email and we'll send you intructions to reset your password
                        </p>
                        <form className="auth-form-validation">
                            <div className="input-field">
                                <label htmlFor="email" className="input-label">
                                    Email or Phone number
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Email or Phone number"
                                    autoComplete="off"
                                    required
                                    name='email_or_phone'
                                    value={account.email_or_phone}
                                    onChange={onChangeValue}
                                />
                            </div>
                            {!Loading && (
                                <button type="submit" className="btn-submit" onClick={FourgotSubmit}>
                                    Send
                                </button>
                            )}

                            {Loading && (

                                <button type="submit" className="btn-submit" disabled>
                                    <i className="fa fa-spinner fa-spin fa-3x  fa-lg"></i> <span>Ongoing processing...</span>
                                </button>
                            )}
                          
                            <Link to={"/login"} className="btn-back-to-login">
                                Back to login
                            </Link>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPass