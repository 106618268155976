import { Route, Routes } from "react-router-dom"
import Home from "../../views/home"
import Settings from "../../views/settings"
import Partners from "../../views/partners"
import AttributeVoucher from "../../views/partners/attributeVoucher"
import Vouchers from "../../views/vouchers"
import Profil from "../../views/profil"
import Security from "../../views/profil/security"
import Ussd from "../../views/ussd"

const AppRoutes = () => {
   return (
    <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/home" element={<Home/>}></Route>
        <Route path="/settings" element={<Settings/>} />
        <Route path="/partners" element={<Partners/>} />
        <Route path="/attribute-voucher/:id_partner"  element={<AttributeVoucher/>}/>
        <Route path="/vouchers" element={<Vouchers/>} />
        <Route path="/user-profile" element={<Profil/>} />
        <Route path="/security-parameters" element={<Security />} />
        <Route path="/ussd-configuration" element={<Ussd/>}/>
    </Routes>
   )
}

export default AppRoutes