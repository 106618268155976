import { useEffect, useRef, useState } from 'react';
import api from '../../config/Config'
import { logoLight, logoSm } from '../icons'
import { Link, useNavigate } from 'react-router-dom';
import { BellFilled, CaretDownOutlined, DeliveredProcedureOutlined, HomeOutlined, LogoutOutlined, 
    MenuOutlined, MessageOutlined, SettingOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import toast, { Toaster } from 'react-hot-toast';

const Header = () => {
    // Créer un état pour la visibilité du menu
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [profile, setProfile] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const profRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: any) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuOpen(false);
        }
    };
    const handleProfile = (event: any) => {
        if (profRef.current && !profRef.current.contains(event.target)) {
            setProfile(false);
        }
    };

    // Créer une fonction qui inverse la visibilité du menu
    useEffect(() => {
        window.addEventListener("click", handleClickOutside);
        window.addEventListener("click", handleProfile);
        return () => {
            window.removeEventListener("click", handleClickOutside);
            window.removeEventListener("click", handleProfile);
        };

    }, []);

    function logout() {
        api.getHttpRequest('/user/profile/login-out', {}, 'POST').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                localStorage.clear();
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })

    }

    return (
        <>
            <section id="header" className="bg-white">
                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-12 my-auto d-flex py-1">
                            <div onClick={() => navigate("/home")} className="logo my-auto">
                                <img src={logoLight} height="40" alt="" />
                            </div>
                            {/* <div className="homeLink my-auto mx-4"><Link to="/home" className="active fw-bold nav-link text-dark">Home</Link></div> */}

                            
                            <div className="searchBox my-auto d-flex">

                                <div className="searchSelector" ref={menuRef}>
                                    <div
                                        className="card-name card-form"

                                        onClick={() => setMenuOpen(!menuOpen)}
                                    >
                                        <div className="input-wrapper">
                                            <div className="card-grp" id="cardGrap">
                                                <div className="card-input" id="cardInput">
                                                    Menus
                                                </div>
                                                <div className="arrow">
                                                    <MenuOutlined style={{ color: 'black' }} className="menu-mobile"/>
                                                </div>
                                            </div>


                                            <ul style={{ display: menuOpen ? 'block' : 'none' }} className="card-list">
                                                <li>
                                                    <Link to="/home" className="nav-link p-0 text-dark">
                                                        {/* <img
                                                                src="img/Maindashboard.png"
                                                                className="align-middle me-3 bgDashboard"
                                                            /> */}
                                                        <HomeOutlined style={{ fontSize: 30, padding: 5, color: 'white' }} className="align-middle me-3 bgDashboard" />
                                                        Dashboard
                                                    </Link>

                                                </li>
                                                <li>
                                                    <Link to="/partners" className="nav-link p-0 text-dark">

                                                        <SolutionOutlined style={{ fontSize: 30, padding: 5, color: 'white' }} className="align-middle me-3 bgProfile" />
                                                        Partners
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link to="/vouchers" className="nav-link p-0 text-dark">

                                                        <SolutionOutlined style={{ fontSize: 30, padding: 5, color: 'white' }} className="align-middle me-3 bgProfile" />
                                                        Vouchers
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link to="/ussd-configuration" className="nav-link p-0 text-dark">
                                                        {/* <img
                                                                src="img/department.svg"
                                                                className="align-middle me-3 bgDepartment"
                                                            /> */}
                                                        <DeliveredProcedureOutlined style={{ fontSize: 30, padding: 5, color: 'white' }} className="align-middle me-3 bgDashboard" />
                                                        Ussd
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link to="/settings" className="nav-link p-0 text-dark">
                                                        {/* <img
                                                                src="img/department.svg"
                                                                className="align-middle me-3 bgDepartment"
                                                            /> */}
                                                        <SettingOutlined style={{ fontSize: 30, padding: 5, color: 'white' }} className="align-middle me-3 bgDepartment" />
                                                        Settings
                                                    </Link>
                                                </li>


                                            </ul>

                                        </div>
                                    </div>
                                </div>

                                <div className="searchInput h-100">
                                    <form action="" method="" className="h-100">
                                        <input type="text" className="my-auto" placeholder="Search Here...." />
                                        <button className="btn my-auto"><i className="fa-solid fa-magnifying-glass"></i></button>
                                    </form>
                                </div>
                            </div>

                            <div className=' ms-3 pr-2 my-auto ms-auto d-flex' ref={profRef}>
                                <div className=" notification ms-10 my-auto mr-20">
                                    <Badge count={2}
                                        size="small" style={{ backgroundColor: 'red', marginRight: 15 }}
                                    >
                                        <BellFilled className="notification"
                                            style={{ fontSize: 25, color: '#DCE6EC', marginRight: 15, cursor: 'pointer' }}

                                        />
                                    </Badge>
                                </div>

                                <div className="profileBtn"  >
                                    <div onClick={() => setProfile(!profile)}>
                                        <div className="input-wrapper">

                                            <img src={api.var_.url_avatar + api.initAvatar()} alt="" />

                                            <span className="profileStatus active"></span>


                                        </div>

                                    </div>


                                </div>
                                <div className="NameUser">
                                    <div onClick={() => setProfile(!profile)}>
                                        <div className="input-wrapper">
                                            <div className=" profilName ms-3 my-auto ms-auto">{api.initUser()}
                                                <CaretDownOutlined className="" style={{ color: 'black' }} />
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className="dropdown-menu1" style={{ display: profile ? 'block' : 'none' }}>
                                    <div className="dropdown-header noti-title">
                                        <h6 className="text-overflow m-0">Welcome !</h6>
                                    </div>
                                    <ul >
                                        <li className='dropdownItem'><Link onClick={() => { setProfile(!profile) }} to={`/user-profile`} className='nav-link p-0 text-dark'><UserOutlined className=" me-3" />My Profile</Link></li>

                                        <li className='dropdownItem'><Link onClick={() => { setProfile(!profile) }} to={"/security-parameters"} className='nav-link p-0 text-dark'> <SettingOutlined className=" me-3" />Security</Link></li>

                                        <li className='dropdownItem'><Link to={"/login"} onClick={logout} className='nav-link p-0 text-dark'><LogoutOutlined className=" me-3" />Logout</Link></li>

                                    </ul>
                                </div>


                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Header