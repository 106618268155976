import { CaretDownOutlined, DeliveredProcedureOutlined, PlusOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import toast, { Toaster } from 'react-hot-toast';
import api from '../../../config/Config';
import { useEffect, useState } from 'react';
import requestAttribute from '../../../controller/requestAtribute';
import RowParams from '../../../controller/requestRowParams';
import { useNavigate, useParams } from 'react-router-dom';

const AttributeVoucher = () => {
    const { id_partner } = useParams();
    const data = '';
    const navigate = useNavigate();

    const [Loading, setLoading] = useState(false);
    // Function pour me permettre en charger mon pannier
    const [idSerie, setIdSerie] = useState('');
    const [panier, setPanier] = useState<requestAttribute[]>([]);
    const [tva, setTva] = useState<RowParams[]>([])

    const [note, setNote] = useState('');
    const [orderType, setOrderType] = useState('');
    const [value, setValue] = useState("");

    function handleChange(event: any) {
        setValue(event.target.value);
    }

    useEffect(() => {
        if (localStorage.getItem('onktxonktxwwww') == null) {
            navigate('/login')
        }
        loadConstante()
    }, [])


    const loadConstante = async () => {
        api.getHttpRequest("/parameters/constante/load", data, 'GET').then((response) => {
            const json = response.data;
            if (json.status == 200) {
                setTva(json.data);
            }
        }).catch((error) => {
            // setLoading(false);
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    const addToCard = async () => {

        const id = idSerie;
        if (!/^[1-9]\d*$/.test(id)) {
            // Affiche un message d'erreur
            toast.error("Please enter the series");
            return;
        }

        api.getHttpRequest(`/vouchers/find/${idSerie}`, data, 'GET').then((response) => {
            const json = response.data;

            if (json.status == 200) {

                const item: any = panier.find((i) => i.serie === json.data.serie)

                if (json.data.status_available == 0) {
                    toast.error('This series has already been assigned');
                } else if (item) {
                    toast.error('This series exists in the cart');
                } else {
                    // Sinon, ajoute le produit au panier avec une quantité de 1
                    setPanier([...panier, { ...json.data, quantity: 1 }]);
                    setIdSerie('');
                }


            }

        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Please connect your device to the internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })
    }

    console.log(panier)

    // Attributes Vouchers
    const Attributed_voucher = async () => {

        // Créer un tableau vide pour stocker les promesses
        let promises = [];

        // Parcourir le tableau du panier
        for (let item of panier) {
            // Créer un objet data avec l'id de l'élément
            let data = JSON.stringify({
                "account_investment": id_partner,
                "id_serie": item.id,
                "type": item.type
            });
            // Ajouter la promesse de la requête axios au tableau
            promises.push(api.getHttpRequest("/investments/attribute/voucher", data, 'POST'));
        }
        setLoading(true);
        // Attendre que toutes les promesses soient résolues
        Promise.all(promises).then((responses) => {
            // Parcourir les réponses
            for (let response of responses) {
                const json = response.data;
                if (json.status == 200) {
                    toast.success(json.message);
                } else {
                    toast.error(json.message);
                }
            }
        }).catch((error) => {
            if (!window.navigator.onLine && !error.response && error.code === "ERR_NETWORK") {
                toast.error("Veuillez connecter votre appareil à Internet");
            } else if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        })


        setTimeout(() => {
            setLoading(false);
        }, 3000)

    }

    // Fonction qui retire un produit du panier
    const removeFromCart = (id: any) => {
        const item = panier.find((i) => i.serie === id);

        if (item) {
            if (item.quantity > 1) {
                setPanier(
                    panier.map((i) =>
                        i.id === id ? { ...i, quantity: i.quantity - 1 } : i
                    )
                );
            } else {
                // Sinon, retire le produit du panier
                setPanier(panier.filter((i) => i.serie !== id));
            }
        }
    }

    // Fonction qui calcule le prix total du panier
    const getTotalPrice = () => {
        return panier.reduce(
            (total: any, item: any) => total + parseInt(item.series_available_amount),
            0
        );
    };

    const getAvalaiblePins = () => {
        return panier.reduce(
            (total: any, item: any) => total + parseInt(item.available_pins),
            0
        );
    };

    const result = tva.find(({ name }: any) => name === 'Tva')?.value ?? "0";
    const resultDiscount = tva.find(({ name }: any) => name === 'Discount')?.value ?? "0";
    const vat_amount = getTotalPrice() * (parseInt(result) / 100);
    const discount_amount = getTotalPrice() * (parseInt(resultDiscount) / 100);
    const bigTot = getTotalPrice() + vat_amount - discount_amount;


    return (
        <>
            <div className="container-fluid">
                <div className="row" >
                    <div>
                        <Toaster
                            position="bottom-left"
                            reverseOrder={false}
                        />
                    </div>
                    <div className="col-12 mb-2">
                        <div className="pagesHeading d-flex">
                            {/* <div className="pageIcon item-center bgProfile">
                     
                                <i className="fas fa-person-booth fa-lg  align-middle me-3" style={{ color: 'white', width: 40, height: 40, borderRadius: 5 }}></i>
                            </div> */}

                            <div className="my-auto ms-1 d-flex">
                                <div>
                                    <span className="small-font">Vouchers</span>
                                    <span className="tableHeading d-flex position-relative">
                                        <DeliveredProcedureOutlined style={{ fontSize: 24 }} /> Attribute Voucher
                                        <div className="dropdown categorydrop">
                                            <button className="btn p-0" type="button" id="categoryMenu" data-bs-toggle="dropdown" aria-expanded="false">
                                                {/* <i className="fa-solid fa-caret-down ms-3 fs-5"></i> */}
                                                <CaretDownOutlined className="ms-3 fs-5" style={{ color: 'black' }} />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="categoryMenu">
                                                <h5>Sellers Categories</h5>
                                                {/* <li><Link className="dropdown-item" to="/wallet">Wallet</Link></li>
                                                    <li><Link className="dropdown-item active" to="/investors">Investors</Link></li>
                                                    <li><Link className="dropdown-item" to="/jvpartners">JV Partners</Link></li> */}

                                            </ul>
                                        </div>
                                    </span>
                                </div>
                                <div className="mt-3 ms-4">
                                    <Card style={{ width: 200 }}></Card>

                                </div>
                                <div className="mt-3 ms-4">
                                    <Card style={{ width: 200 }}></Card>

                                </div>
                                <div className="mt-3 ms-4">
                                    <Card style={{ width: 200 }}></Card>

                                </div>
                                <div className="mt-3 ms-4">
                                    <Card style={{ width: 200 }}></Card>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-7">
                        <div className="d-flex mt-3 justify-content-end" style={{ display: 'flex' }}>

                            <div className="ms-1 mb-1">
                                <button className="btn w-100 " style={{ backgroundColor: '#16C0EC', fontSize: 20 }} >
                                    <PlusOutlined style={{ fontSize: 24 }}
                                    /> Add JV Partners</button>
                            </div>
                        </div>

                    </div> */}
                </div>



                <div className="row m-0">
                    <div className="col-12 px-0">
                        <Card style={{ opacity: 0.8 }}>

                            <div className="row" >
                                <div className="col-7 mb-2">
                                    <div className='d-flex'>
                                        <div className="col-4 mr-5">
                                            <div className="inputBox mb-3">
                                                <input type="text" className="w-100 mt-0" placeholder="Attechement serie"
                                                    value={idSerie} onChange={(e) => { setIdSerie(e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="inputBox mb-3">

                                                <button type="button" className="btn w-100  rounded-pill" style={{ backgroundColor: '#16C0EC' }}
                                                    onClick={addToCard}
                                                >Add</button>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-5 mb-2">

                                </div>
                            </div>

                            <div className="row m-0">
                                <div className="col-12 px-0">
                                    <div className="table-responsive mt-3">
                                        <table id="table1"
                                            className="table table-bordered table-striped verticle-middle table-responsive-sm" style={{ border: 0 }}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">N°</th>
                                                    <th scope="col">Id/serie</th>
                                                    <th scope="col">Serie</th>
                                                    <th scope="col">Nomber vouchers</th>
                                                    {/* <th scope="col">Vouchers Used</th> */}
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Series Available Amount</th>
                                                    <th scope="col">Total Amount <br />
                                                        to Pay / Collect
                                                    </th>

                                                </tr>
                                            </thead>
                                            {/* {panierData.length > 0 && ( */}

                                            <tbody id="tpanier">
                                                {panier.map((item: any, index: number) => (

                                                    <tr key={item.id} style={{ borderBottom: '1px solid #E8E8E8' }}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.id}</td>
                                                        <td>{item.serie} <span className="badge badge-success pl-2">{item.type}</span></td>
                                                        <td><span style={{ display: 'flex' }}> <h6 style={{ marginRight: 5 }}>Currenry:</h6>{item.currency}.{item.amount_selling_prince}</span> <br />
                                                            <h6>Available Pins : </h6>
                                                            {item.available_pins} <br />
                                                            <h6>Customer Selling Price:</h6>{item.currency}.{item.amount_selling_prince}
                                                        </td>
                                                        {/* <td><span className="badge badge-success">{item.used}</span></td> */}
                                                        <td>
                                                            <span style={{ display: 'flex' }}> <h6 style={{ marginRight: 5 }}>Pins:</h6>{item.status_available}</span><br />
                                                            <span style={{ display: 'flex', marginTop: 0 }}> <h6 style={{ marginRight: 5 }}>Used:</h6>{item.status_used}</span><br />
                                                            <span style={{ display: 'flex' }}> <h6 style={{ marginRight: 5 }}>Assigned:</h6>{item.status_asigned}</span><br />
                                                            <span style={{ display: 'flex' }}> <h6 style={{ marginRight: 5 }}>Available Pins:</h6>{item.available_pins}</span>
                                                        </td>
                                                        <td><span style={{ display: 'flex', fontSize: 18 }}>{item.currency}.{item.series_available_amount}</span></td>
                                                        <td><span style={{ display: 'flex', fontSize: 18 }}>{item.currency}.{item.series_available_amount}</span></td>
                                                        <td>
                                                            <span style={{ cursor: 'pointer' }}>
                                                                <div data-bs-toggle="tooltip" data-placement="top" title="Close"
                                                                    onClick={() => removeFromCart(item.serie)} >
                                                                    <i className="fas fa-times color-danger"></i>
                                                                </div>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot style={{ backgroundColor: '#CFE1FD' }}>
                                                <th colSpan={3}><span><h5 style={{ fontSize: 26 }}>Total: </h5></span></th>
                                                <th colSpan={2}><span style={{ fontSize: 20 }}>Total Available Pins : <br /><h5>{getAvalaiblePins()}</h5></span></th>

                                                <th><span style={{ fontSize: 20 }}>Tsh: {getTotalPrice()}</span></th>
                                                <th colSpan={3}><span style={{ fontSize: 20 }}>Tsh: {getTotalPrice()}</span></th>
                                            </tfoot>

                                            {/* )} */}
                                        </table>
                                    </div>

                                    <div className="row">

                                        <div className="col-lg-3 col-sm-3">
                                        </div>
                                        <div className="col-lg-6 col-sm-9 ms-auto">
                                            {panier.length > 0 && (
                                                <div className="mt-1 col-sm-12">
                                                    <div className="mt-4 ">


                                                        {!Loading && (
                                                            <button type="button"
                                                                id="btn_save_vouchers"
                                                                className="btn w-100 btn-primary rounded-pill" onClick={() => { Attributed_voucher() }}>Save</button>
                                                        )}

                                                        {Loading && (

                                                            <button type="button" className="btn w-100 btn-primary rounded-pill" disabled

                                                            >
                                                                <i className="fa fa-spinner fa-spin fa-3x  fa-lg" style={{ fontSize: 15 }}></i> <span>Processing...</span>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </Card>
                    </div>
                </div>

            </div>
        </>
    )
}

export default AttributeVoucher